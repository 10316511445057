export const defaultItemBankFieldOrder = [
	"vendors",
	"vendor_codes",
	"manufacturer_codes",
	"manufacturer",
	"descriptions",
	"description",
	"colors",
	"sub_descriptions",
	"custom_fields",
	"custom_field",
	"finishes",
	"finish_code",
	"sizes",
];

export const itemBankApiModel = {
	custom_fields: [],
	custom_item: false,
	descriptions: [{ value: "", isVendor: false }],
	manufacturer: null,
	previewOrder: [],
	material: "",
	finishes: [],
	colors: [],
	sizes: [],
	variants: [
		{
			manufacturer_code: "",
			vendor_code: "",
			finish: "",
			color: null,
			size: null,
			uom: "",
			price: null,
		},
	],
	vendor_codes: [],
	manufacturer_codes: [],
	sub_descriptions: [],
	uom: [],
	vendors: [],
	price: "",
	manufacturer_code: "",
};

export const variantApiModel = {
	manufacturer_code: "",
	vendor_code: "",
	finish: "",
	color: null,
	size: null,
	uom: "",
	price: null,
};
