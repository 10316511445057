import { FIELD_TYPES } from "../constants";

export const mapApiObjectToSelect = (items) => {
	return items.map((item) => ({
		value: item.name,
		label: item.name,
	}));
};

export const updateStateObjectAfterSelectChanged = (e, fieldType, st) => {
	switch (fieldType) {
		case FIELD_TYPES.manufacturer:
			st.manufacturer.name = e?.value || "";
			break;
		case FIELD_TYPES.size.width.uom:
			st.size.width.uom = e?.value || "";
			break;
		case FIELD_TYPES.size.length.uom:
			st.size.length.uom = e?.value || "";
			break;
		case FIELD_TYPES.size.height.uom:
			st.size.height.uom = e?.value || "";
			break;
		case FIELD_TYPES.unitOfMeasurement:
			st.uom = e?.value || "";
			break;
		case FIELD_TYPES.vendor:
			st.vendor.name = e?.value || "";
			break;
		default:
			break;
	}

	return st;
};

export const updateStateObjectAfterInputChanged = (e, fieldType, idx, st) => {
	let field = {};
	switch (fieldType) {
		case FIELD_TYPES.customField.name:
			field = st.custom_fields[idx] || {};
			field.name = e.currentTarget.value;
			st.custom_fields[idx] = field;
			break;
		case FIELD_TYPES.customField.value:
			field = st.custom_fields[idx] || {};
			field.value = e.currentTarget.value;
			st.custom_fields[idx] = field;
			break;
		case FIELD_TYPES.size.width.value:
			st.size.width.value = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.size.length.value:
			st.size.length.value = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.size.height.value:
			st.size.height.value = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.price:
			st.price = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.descriptions.value:
			field = st.descriptions[idx] || {};
			field.value = e.currentTarget.value;
			st.descriptions[idx] = field;
			break;
		case FIELD_TYPES.colorCode:
			field = st.sub_items[idx] || {
				finish: "",
				color: {
					code: "",
					name: "",
				},
			};
			field.color.code = e.currentTarget.value;
			st.sub_items[idx] = field;
			break;
		case FIELD_TYPES.color:
			field = st.sub_items[idx] || {
				finish: "",
				color: {
					code: "",
					name: "",
				},
			};
			field.color.name = e.currentTarget.value;
			st.sub_items[idx] = field;
			break;
		case FIELD_TYPES.finish:
			field = st.sub_items[idx] || {
				finish: "",
				color: {
					code: "",
					name: "",
				},
			};
			field.finish = e.currentTarget.value;
			st.sub_items[idx] = field;
			break;
		case FIELD_TYPES.manufacturer_code:
			st.manufacturer_code = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.vendor_code:
			st.vendor_code = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.subDescription.description:
			st.sub_description.description = e?.currentTarget.value || "";
			break;
		case FIELD_TYPES.subDescription.code:
			st.sub_description.code = e?.currentTarget.value || "";
			break;
		default:
			break;
	}

	return st;
};

export const getOptions = (option) => (option ? [{ value: option, label: option }] : []);

export const isFormEmpty = (props) => {
	if (props) {
		const isEmpty = Object.values(props).every((prop) => {
			if (typeof prop === "object") {
				return isFormEmpty(prop);
			}
			return prop === "" || prop === null || prop === undefined;
		});
		return isEmpty;
	}
	return false;
};

export const sortSelectOptionsByValue = (a, b) => {
	if (a.value > b.value) {
		return 1;
	}
	if (a.value < b.value) {
		return -1;
	}
	return 0;
};

export const sortSelectOptionsByLabel = (a, b) => (a?.label || "").toLowerCase().localeCompare((b?.label || "").toLowerCase());
