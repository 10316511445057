import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { axiosInstance, getLoggedInUser } from "../helpers";
import Bugsnag from "@bugsnag/js";
import { auth, onAuthStateChanged } from "../firebase/firebaseInit";
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Spinner from "../components/Spinner";
import { requestPermission } from "../firebase/firebaseMessagingSW";
import { API_HOST } from "../config";

const AuthContext = createContext({
	user: null,
	setUser: (data) => {},
	sessionLoaded: false,
});

export function AuthProvider({ children }) {
	const [userData, setUserData] = useState(null);
	const history = useHistory();
	const location = useLocation();
	const [sessionLoaded, setSessionLoaded] = useState(false);
	const [sessionCreating, setSessionCreating] = useState(false);

	const shareToken = useMemo(() => {
		const searchParams = new URLSearchParams(location.search);
		return searchParams.get("token");
	}, [location.search]);

	const isSharedPage = !!shareToken || location.pathname.match("/share/")?.[0];

	const redirectToLogin = () => {
		const searchParams = new URLSearchParams(location.search);
		if (!searchParams.has("redirect") && !location.pathname.includes("/account/login")) {
			searchParams.append("redirect", location.pathname);
		}
		history.push(`/account/login?${searchParams.toString()}`);
	};

	const loadCurrentUser = async (user, disableLoadedOnFalse = false) => {
		if (!user) {
			await axiosInstance.post("/users/clear_session");
			setUserData(null);
			setSessionLoaded(true);
			if (!isSharedPage) {
				redirectToLogin();
			}
			return;
		}
		if (!sessionCreating) {
			try {
				setSessionCreating(true);
				let data;
				try {
					const loggedInUser = await getLoggedInUser();
					data = loggedInUser.data;
				} catch (e) {
					console.error("getLoggedInUser", e);
				}

				if (data == null) {
					const token = await user.getIdToken(true);
					await axiosInstance.post("/users/create_session", null, {
						method: "POST",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "*/*",
						},
					});
					const result = await getLoggedInUser();
					data = result.data;
				}
				setUserData(data);
				try {
					requestPermission(data);
				} catch (e) {
					console.error("requestPermission", e);
				}
				if (location.pathname.includes("/account/login")) {
					const searchParams = new URLSearchParams(location.search);
					if (searchParams.has("redirect")) {
						const redirect = searchParams.get("redirect");
						searchParams.delete("redirect");
						if (redirect === "/account/logout") history.push("/jobs/all");
						else history.push(`${redirect}?${searchParams.toString()}`);
					} else {
						history.push("/jobs/all");
					}
				}
				setSessionLoaded(true);
			} catch (e) {
				await axiosInstance.post("/users/clear_session");
				setUserData(null);
				if (!isSharedPage) {
					redirectToLogin();
				}
				Bugsnag.notify(e);
				if (!disableLoadedOnFalse) {
					setSessionLoaded(true);
				}
			}
			setSessionCreating(false);
		}
	};

	// listen for token changes
	// call setUser and write new token as a cookie
	useEffect(() => {
		if (!isSharedPage) {
			onAuthStateChanged((user) => {
				loadCurrentUser(user);
			});
			return auth.onIdTokenChanged(async (user) => {
				loadCurrentUser(user);
			});
		}

		if (isSharedPage && !location.pathname.match("/share/")?.[0]) {
			history.push(`/share${location.pathname}${location.search}`);
		} else if (isSharedPage && !shareToken) {
			redirectToLogin();
		}
	}, [location.pathname, isSharedPage]);

	// force refresh the token every 10 minutes
	useEffect(() => {
		const handle = setInterval(
			async () => {
				const user = auth.currentUser;
				if (user) await user.getIdToken(true);
			},
			10 * 60 * 1000,
		);

		// clean up setInterval
		return () => clearInterval(handle);
	}, []);

	return (
		<AuthContext.Provider value={{ sessionLoaded, user: userData, setUser: setUserData }}>
			{sessionLoaded || isSharedPage ? (
				children
			) : (
				<Row>
					<Col sm={12}>
						<div className="d-flex justify-content-center">
							<Spinner className="text-primary m-2" type="grow" size={"lg"} />
						</div>
					</Col>
				</Row>
			)}
		</AuthContext.Provider>
	);
}

export default AuthProvider;

export function useAuthContext() {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuthContext must be used within a AuthProvider");
	}
	return context;
}
