export const pathConstants = {
	itemBank: {
		root: "/itembank",
		all: "/itembank/all",
		variant: "/itembank/:itemId/variant/:variant",
		edit: "/itembank/:itemId/edit",
		selectedItem: "/itembank/:itemId",
	},
	dashboard: {
		root: "/dashboards",
		edit: "/dashboards/:stateId",
		location: "/dashboards/locations/:locationId",
	},
	test: {
		root: "/test",
	},
	vendor: {
		root: "/vendor",
		all: "/itembank/all?tab=1",
		profile: "/vendor/:vendorId",
	},
	manufacturer: {
		root: "/manufacturer",
		profile: "/manufacturer/:manufacturerId",
	},
	material: {
		root: "/material",
		profile: "/material/:materialId",
	},
	project: {
		root: "/jobs",
		all: "/jobs/all",
	},
	userProfile: {
		root: "/profile",
		edit: "/profile/edit",
		notifications: "/profile/notifications",
	},
	conversations: {
		all: "/conversations",
	},
	share: {
		jobs: {
			all: "/share/jobs/all",
			oneJob: "/share/jobs/:jobId",
		},
		purchaseOrders: {
			all: "/share/purchaseorders/all",
			oneItem: "/share/purchaseorders/:purchaseOrderId",
		},
		inventoryItems: {
			all: "/share/items/all",
			oneItem: "/share/items/:itemId",
		},
	},
	templates: {
		root: "/templates/all",
		oneItem: "/templates/:templateId",
	},
};
