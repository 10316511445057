import { getCreatableSelectValue } from "../pages/itemBank/components/ItemBankCreatableSelect";

export const materialTypes = [
	"Adhesive",
	"Broadloom Carpet",
	"Carpet Tile",
	"Glass",
	"Hard Tile",
	"LVT",
	"Resilient",
	"Setting Materials",
	"Stone",
	"Sundries",
	"Trim",
	"VCT",
	"Wood",
];

export const unitOfMeasurementMetrics = {
	CM: { shortName: "CM", longName: "centimeters (CM)", fractions: "decimals" },
	FT: { shortName: "'", longName: "feet (‘)", fractions: "decimals" },
	GL: { shortName: "GL", longName: "gallons (GL)", fractions: "decimals" },
	IN: {
		shortName: '"',
		longName: "inches (“)",
		fractions: [
			"0",
			"1/32",
			"1/16",
			"3/32",
			"1/8",
			"5/32",
			"3/16",
			"7/32",
			"1/4",
			"9/32",
			"5/16",
			"11/32",
			"3/8",
			"13/32",
			"7/16",
			"15/32",
			"1/2",
			"17/32",
			"9/16",
			"19/32",
			"5/8",
			"21/32",
			"11/16",
			"23/32",
			"3/4",
			"25/32",
			"13/16",
			"27/32",
			"7/8",
			"29/32",
			"15/16",
			"31/32",
		],
	},
	LB: { shortName: "LB", longName: "pound (LB)", fractions: "decimals" },
	MM: { shortName: "MM", longName: "millimeters (MM)", fractions: "decimals" },
	M: { shortName: "M", longName: "meters (M)", fractions: "decimals" },
	OZ: { shortName: "OZ", longName: "ounces (OZ)", fractions: "decimals" },
	QT: { shortName: "QT", longName: "quart (QT)", fractions: "decimals" },
	YD: { shortName: "YD", longName: "yards (YD)", fractions: "decimals" },
	RL: { shortName: "RL", longName: "rolls (RL)", fractions: "decimals" },
};

export const unitOfMeasurementPieces = ["SF", "LF", "EA", "PCS", "PL (Pails)", "SY", "RL"];

export const uomMetrics = Object.keys(unitOfMeasurementMetrics).map((key) =>
	getCreatableSelectValue(unitOfMeasurementMetrics[key].shortName, key === "FT" || key == "IN" ? key : null),
);

export const uomOptions = unitOfMeasurementPieces.map((value) => getCreatableSelectValue(value));
