import React, { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import { Location, State } from "../models/stateAndLocationModel";

interface StateAndLocationContextProps {
	selectedState: State | null;
	setSelectedState: Dispatch<SetStateAction<State | null>>;
	selectedLocation: Location[] | null;
	setSelectedLocation: Dispatch<SetStateAction<Location[] | null>>;
}

const StateAndLocationContext = createContext<StateAndLocationContextProps>({
	selectedState: null,
	setSelectedState: null,
	selectedLocation: null,
	setSelectedLocation: null,
});

export const StateAndLocationProvider = ({ children }) => {
	const [selectedState, setSelectedState] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);

	return (
		<StateAndLocationContext.Provider value={{ selectedState, setSelectedState, selectedLocation, setSelectedLocation }}>
			{children}
		</StateAndLocationContext.Provider>
	);
};

export default StateAndLocationProvider;

export const useStateAndLocationContext = () => {
	const context = useContext(StateAndLocationContext);
	if (context === undefined) {
		throw new Error("StateAndLocationContext must be used within a StateAndLocationProvider");
	}
	return context;
};
