import React, { Component } from "react";
import { axiosInstance } from "./api";
import { ADJUSTMENT_OPTIONS } from "../constants";
import { apiGetAdjustmentByIds } from "../api/inventoryAdjustmentApi";
const PackingListContext = React.createContext();

export class PackingListContextProvider extends Component {
	state = {
		assignableInventoryItems: [],
		assignableAdjustmentItems: [],
		selectedPurchaseOrder: null,
	};

	setAssignableInventoryItems = (assignableInventoryItems) => {
		this.setState({ assignableInventoryItems: assignableInventoryItems });
	};

	setAssignableAdjustmentItems = (assignableAdjustmentItems) => {
		this.setState({ assignableAdjustmentItems: assignableAdjustmentItems });
	};

	setSelectedPurchaseOrder = async (selectedPurchaseOrder) => {
		const { selectedPurchaseOrder: previousSelectedPurchaseOrder } = this.state;
		if (selectedPurchaseOrder && (previousSelectedPurchaseOrder == null || selectedPurchaseOrder?._id !== previousSelectedPurchaseOrder?._id)) {
			if ((selectedPurchaseOrder?.inventory_items || []).length > 0 && typeof selectedPurchaseOrder.inventory_items[0] === "string") {
				const response = await axiosInstance.get(`/purchaseOrders/${selectedPurchaseOrder._id}`);
				const { purchaseOrder } = await response.data;
				selectedPurchaseOrder = purchaseOrder;
			}
			this.setState({ selectedPurchaseOrder: selectedPurchaseOrder }, () => {
				this.loadAssignableElements();
			});
		}
	};

	loadAssignableElements = () => {
		const { selectedPurchaseOrder } = this.state;
		let idList = [];
		(selectedPurchaseOrder?.inventory_items || []).forEach((item) => {
			(item?.inventory_adjustments || []).forEach((adjustmentId) => idList.push(adjustmentId));
		});

		this.setAssignableInventoryItems(selectedPurchaseOrder.inventory_items);

		apiGetAdjustmentByIds(idList).then((adjustments) => {
			this.setAssignableAdjustmentItems(
				adjustments.data.filter(
					({ type }) =>
						type === ADJUSTMENT_OPTIONS.INVENTORY_DIRECTLY_TO_JOBSITE ||
						type === ADJUSTMENT_OPTIONS.INVENTORY_ARRIVED_TO_WAREHOUSE ||
						type === ADJUSTMENT_OPTIONS.RECEIVED_AT_JOBSITE,
				),
			);
		});
	};

	render() {
		const { setAssignableAdjustmentItems, setAssignableInventoryItems, setSelectedPurchaseOrder } = this;
		return (
			<PackingListContext.Provider
				value={{
					setAssignableAdjustmentItems,
					setAssignableInventoryItems,
					setSelectedPurchaseOrder,
					...this.state,
				}}>
				{this.props.children}
			</PackingListContext.Provider>
		);
	}
}

export const PackingListContextConsumer = PackingListContext.Consumer;

export default PackingListContext;
