// @flow
import React from "react";
import { Button, Col, Form, Input, Label, Row } from "reactstrap";

class DeleteForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			confirmInput: "",
		};
	}

	handleInputChange = (e) => {
		const { name, value } = e.target;
		this.setState((prevState) => {
			return {
				...prevState,
				[name]: value,
			};
		});
	};

	renderDeleteButtonText = () => {
		const { loading, buttonTitleLoading, buttonTitle } = this.props;
		const label = buttonTitle || "Delete";
		const loadingLabel = buttonTitleLoading || buttonTitle || "Deleting";
		if (loading) {
			return <span>{loadingLabel}...</span>;
		} else {
			return <span>{label}</span>;
		}
	};

	handleSubmit = () => {
		if (this.props.handleSubmit) {
			return this.props.handleSubmit();
		}
	};

	render() {
		const { confirmInput } = this.state;
		const { buttonColor, warningText, confirmText } = this.props;
		const confirmTextValue = confirmText || "DELETE";
		return (
			<Form onClick={(event) => event.stopPropagation()}>
				<Row>
					<Col>
						<span className="text-muted">{warningText || ""}</span>
					</Col>
				</Row>
				<Row className="mt-2 mb-2">
					<Col>
						<Label>Enter '{confirmTextValue}' to Confirm</Label>
						<Input name="confirmInput" type="text" value={confirmInput} onChange={this.handleInputChange} />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button disabled={confirmInput !== confirmTextValue} color={buttonColor || "danger"} onClick={this.handleSubmit}>
							{this.renderDeleteButtonText()}
						</Button>
					</Col>
				</Row>
			</Form>
		);
	}
}

export default DeleteForm;
