type UserRoleType = {
	textkey: `${UserRoles}`;
	name: string;
};

export enum UserRoles {
	ADMIN = "ADMIN",
	PROJECT_MANAGER = "PROJECT_MANAGER",
	WAREHOUSE_MANAGER = "WAREHOUSE_MANAGER",
	BILLING_ADMIN = "BILLING_ADMIN",
	PURCHASE_AGENT = "PURCHASE_AGENT",
	WAREHOUSE_ATTENDANT = "WAREHOUSE_ATTENDANT",
	SUPERINTENDENT = "SUPERINTENDENT",
	CONTRACTOR = "CONTRACTOR",
}

type IUserRoles = {
	[key in UserRoles]: UserRoleType;
};

export const USER_ROLE: IUserRoles = {
	[UserRoles.ADMIN]: { textkey: UserRoles.ADMIN, name: "Admin" },
	[UserRoles.PROJECT_MANAGER]: { textkey: UserRoles.PROJECT_MANAGER, name: "Project Manager" },
	[UserRoles.WAREHOUSE_MANAGER]: { textkey: UserRoles.WAREHOUSE_MANAGER, name: "Warehouse Manager" },
	[UserRoles.BILLING_ADMIN]: { textkey: UserRoles.BILLING_ADMIN, name: "Billing Admin" },
	[UserRoles.PURCHASE_AGENT]: { textkey: UserRoles.PURCHASE_AGENT, name: "Purchasing Agent" },
	[UserRoles.WAREHOUSE_ATTENDANT]: { textkey: UserRoles.WAREHOUSE_ATTENDANT, name: "Warehouse Attendant" },
	[UserRoles.SUPERINTENDENT]: { textkey: UserRoles.SUPERINTENDENT, name: "Superintendent" },
	[UserRoles.CONTRACTOR]: { textkey: UserRoles.CONTRACTOR, name: "Contractor" },
};

type UserNotification = {
	_id?: string;
	key: string;
	label: string;
	NO_PROJECT?: boolean;
	group: USER_NOTIFICATION_GROUPS;
	requiredRoles: UserRoleType[];
	hidden?: boolean;
};

interface UserNotifications {
	[key: string]: UserNotification;
}

export enum USER_NOTIFICATION_GROUPS {
	"GENERAL" = "GENERAL",
	"ADJUSTMENT" = "ADJUSTMENT",
}

export const USER_NOTIFICATIONS: UserNotifications = {
	RECEIVED_AT_JOBSITE: {
		key: "RECEIVED_AT_JOBSITE",
		label: "Received at Jobsite",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
	INVENTORY_ARRIVED_TO_WAREHOUSE: {
		key: "INVENTORY_ARRIVED_TO_WAREHOUSE",
		label: "Received at Warehouse",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
	INVENTORY_DIRECTLY_TO_JOBSITE: {
		key: "INVENTORY_DIRECTLY_TO_JOBSITE",
		label: "Directly to Install",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
	RETURN_FROM_JOBSITE: {
		key: "RETURN_FROM_JOBSITE",
		label: "Returned from Jobsite",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [],
	},
	ADDED_TO_STORAGE: {
		key: "ADDED_TO_STORAGE",
		label: "Added to Storage",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [],
	},
	INVENTORY_REMOVED_FROM_WAREHOUSE: {
		key: "INVENTORY_REMOVED_FROM_WAREHOUSE",
		label: "Removed from Warehouse",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [],
	},
	INVENTORY_FROM_WAREHOUSE_TO_JOBSITE: {
		key: "INVENTORY_FROM_WAREHOUSE_TO_JOBSITE",
		label: "From Storage to Install",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [],
	},
	INVENTORY_REQUEST_TO_JOBSITE: {
		key: "INVENTORY_REQUEST_TO_JOBSITE",
		label: "Requested to Jobsite",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.WAREHOUSE_ATTENDANT],
	},
	INVENTORY_MOVED_TO_ANOTHER_PROJECT_TO: {
		key: "INVENTORY_MOVED_TO_ANOTHER_PROJECT_TO",
		label: "Moved to (Storage)",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PROJECT_MANAGER, USER_ROLE.PURCHASE_AGENT],
	},
	INVENTORY_MOVED_TO_ANOTHER_PROJECT_FROM: {
		key: "INVENTORY_MOVED_TO_ANOTHER_PROJECT_FROM",
		label: "Moved from (Storage)",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PROJECT_MANAGER, USER_ROLE.PURCHASE_AGENT],
	},
	TRANSFER_TO_ANOTHER_PROJECT_INSTALL_TO: {
		key: "TRANSFER_TO_ANOTHER_PROJECT_INSTALL_TO",
		label: "Moved to (Install)",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PROJECT_MANAGER, USER_ROLE.PURCHASE_AGENT],
	},
	TRANSFER_TO_ANOTHER_PROJECT_INSTALL_FROM: {
		key: "TRANSFER_TO_ANOTHER_PROJECT_INSTALL_FROM",
		label: "Moved to (Install)",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [USER_ROLE.PROJECT_MANAGER, USER_ROLE.PURCHASE_AGENT],
	},
	ADJUSTMENT_UPDATED: {
		key: "ADJUSTMENT_UPDATED",
		label: "Adjustment Updated",
		group: USER_NOTIFICATION_GROUPS.ADJUSTMENT,
		requiredRoles: [],
	},
	PURCHASE_ORDER_DAILY: {
		key: "PURCHASE_ORDER_DAILY",
		label: "Payment Due (Day of)",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.BILLING_ADMIN],
	},
	PURCHASE_ORDER_WEEKLY: {
		key: "PURCHASE_ORDER_WEEKLY",
		label: "Payment Due (Week of)",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.BILLING_ADMIN],
	},
	PURCHASE_ORDER_ONE_WEEKLY: {
		key: "PURCHASE_ORDER_ONE_WEEKLY",
		label: "Payment Due (One Week)",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.BILLING_ADMIN],
	},
	CONVERSATIONS: {
		key: "CONVERSATIONS",
		label: "Conversations",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		NO_PROJECT: true,
		requiredRoles: [USER_ROLE.BILLING_ADMIN],
	},
	ARRIVED_THIS_WEEK: {
		key: "ARRIVED_THIS_WEEK",
		label: "Arrived This Week",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [],
	},
	ARRIVING_THIS_WEEK: {
		key: "ARRIVING_THIS_WEEK",
		label: "Arriving This Week",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [],
	},
	PROJECT_MANAGER_ADDED_TO_JOB: {
		key: "PROJECT_MANAGER_ADDED_TO_JOB",
		label: "Project Manager Added to Job",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		NO_PROJECT: true,
		requiredRoles: [],
	},
	// ADD IT WHEN THE BACKEND IS READY
	// MATERIAL_REMAINING_WARNING: {
	// 	key: "MATERIAL_REMAINING_WARNING",
	// 	label: "Material Remaining Warning",
	// 	group: USER_NOTIFICATION_GROUPS.GENERAL,
	// 	requiredRoles: [
	// 		USER_ROLE.WAREHOUSE_ATTENDANT
	// 	]
	// },
	ADDED_TO_STATE: {
		key: "ADDED_TO_STATE",
		label: "Added to State",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		NO_PROJECT: true,
		requiredRoles: [USER_ROLE.WAREHOUSE_ATTENDANT],
	},
	ADDED_TO_LOCATION: {
		key: "ADDED_TO_LOCATION",
		label: "Added to Location",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		NO_PROJECT: true,
		requiredRoles: [USER_ROLE.WAREHOUSE_ATTENDANT],
	},
	UPDATED_DELIVERY_DATE: {
		key: "UPDATED_DELIVERY_DATE",
		label: "Updated Delivery Date",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.WAREHOUSE_ATTENDANT],
	},
	LINK_SHARE: {
		key: "LINK_SHARE",
		label: "IMS link share",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [],
		hidden: true,
	},
	ORDER_REMINDER: {
		key: "ORDER_REMINDER",
		label: "Order Reminder",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
	ITEM_DUE_DATE: {
		key: "ITEM_DUE_DATE",
		label: "Item Due Date",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
	ITEM_PAST_DUE_DATE: {
		key: "ITEM_PAST_DUE_DATE",
		label: "Item Past Due Date",
		group: USER_NOTIFICATION_GROUPS.GENERAL,
		requiredRoles: [USER_ROLE.PURCHASE_AGENT],
	},
};
