import React, { createContext, useState } from "react";

type contextProps = {
	isModalOpen: boolean;
	setIsModalOpen: (a: boolean) => void;
	conversationId: null | string;
	setConversationId: (a: string) => void;
};

const conversationContextValues: contextProps = {
	isModalOpen: false,
	setIsModalOpen: (a) => {},
	conversationId: null,
	setConversationId: (a) => {},
};

export const ConversationContext = createContext(conversationContextValues);

const ConversationContextProvider = ({ children }: any) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [conversationId, setConversationId] = useState(null);
	return (
		<ConversationContext.Provider
			value={{
				isModalOpen,
				setIsModalOpen,
				conversationId,
				setConversationId,
			}}>
			{children}
		</ConversationContext.Provider>
	);
};

export default ConversationContextProvider;
