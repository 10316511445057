// @flow

import { combineReducers } from "redux";
import Layout from "./layout/reducers";
import AppMenu from "./appMenu/reducers";

export default combineReducers({
	AppMenu,
	Layout,
});
