import { getMessaging, getToken } from "firebase/messaging";
import { firebaseApp } from "./firebaseInit";
import Bugsnag from "@bugsnag/js";
import { axiosInstance } from "../helpers";
import { SERVICE_WORKER } from "../config";

let messaging = null;
export const getMessagingApp = () => {
	try {
		if (messaging) {
			return messaging;
		}
		messaging = getMessaging(firebaseApp);
		return messaging;
	} catch (e) {
	}
};

export function requestPermission(userData) {
	Notification.requestPermission().then(async (permission) => {
		if (permission === "granted") {
			const token = await getFCMToken();
			if (userData && userData.fcmToken !== token) {
				axiosInstance.put("/users", { fcmToken: token });
			}
		} else {
			console.log("Permission is not granted");
		}
	});
}

export async function getFCMToken() {
	try {
		const serviceWorkerRegistration = await navigator.serviceWorker.register(`/${SERVICE_WORKER}`);
		return await getToken(getMessagingApp(), { vapidKey: undefined, serviceWorkerRegistration });
	} catch (e) {
		return undefined;
	}
}