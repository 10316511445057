import { axiosInstance } from "../helpers";

const baseRoute = "/conversations";

export const getCovnersationsList = async () => {
	const response = await axiosInstance.get(baseRoute);
	return response.data;
};

export const getSelectedConversation = async (id: string) => {
	const response = await axiosInstance.get(`${baseRoute}/${id}`);
	return response.data;
};

export const getUnseenConversations = async () => {
	const response = await axiosInstance.get(`${baseRoute}/unseen`);
	return response.data.count.length;
};
