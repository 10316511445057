import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import { getLoggedInUser } from "../helpers/authUtils";
import { pathConstants } from "./pathConstants";
import Test from "../pages/test/Test";
import { UserRoles } from "../constants";

// lazy load all the views

// auth
const Login = React.lazy(() => import("../pages/auth/Login"));
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const Register = React.lazy(() => import("../pages/auth/Register"));
//Admin
const Admin = React.lazy(() => import("../pages/admin"));
// UserProfile
const UserProfile = React.lazy(() => import("../pages/userProfile"));
const EditUserProfile = React.lazy(() => import("../pages/userProfile/editUser"));
const UserNotifications = React.lazy(() => import("../pages/userProfile/notifications"));
const Conversations = React.lazy(() => import("../pages/conversations"));

// dashboard
const EcommerceDashboard = React.lazy(() => import("../pages/dashboards/Ecommerce"));
// const CRMDashboard = React.lazy(() => import('../pages/dashboards/CRM'));
// const AnalyticsDashboard = React.lazy(() => import('../pages/dashboards/Analytics'));
// const ProjectDashboard = React.lazy(() => import('../pages/dashboards/Project'));

//jobs
const AllJobs = React.lazy(() => import("../pages/jobs"));
const SelectedJob = React.lazy(() => import("../pages/jobs/selectedJob"));
const EditJob = React.lazy(() => import("../pages/jobs/editJob"));

//purchaseOrders
const AllPurchaseOrders = React.lazy(() => import("../pages/purchaseOrders/PurchaseOrders"));
const SelectedPurchaseOrder = React.lazy(() => import("../pages/purchaseOrders/selectedPurchaseOrder"));
const EditPurchaseOrder = React.lazy(() => import("../pages/purchaseOrders/editPurchaseOrder"));

//inventory items
const AllInventoryItems = React.lazy(() => import("../pages/inventoryItems"));
const SelectedItem = React.lazy(() => import("../pages/inventoryItems/selectedItem"));
const EditItem = React.lazy(() => import("../pages/inventoryItems/editItem"));

const AllInventoryAdjustments = React.lazy(() => import("../pages/inventoryAdjustments"));

//item bank
const ItemBank = React.lazy(() => import("../pages/itemBank"));
const SelectedItemBankItem = React.lazy(() => import("../pages/itemBank/selectedItem"));
const EditItemBankItem = React.lazy(() => import("../pages/itemBank/editItem"));

//vendor
const VendorProfile = React.lazy(() => import("../pages/vendor/VendorProfile"));
const StateList = React.lazy(() => import("../pages/state/index"));
const StateProfile = React.lazy(() => import("../pages/state/profile"));
const LocationProfile = React.lazy(() => import("../pages/location/index"));
const MaterialProfile = React.lazy(() => import("../pages/material/index"));
const ManufacturerProfile = React.lazy(() => import("../pages/manufacturer/index"));

const Calendar = React.lazy(() => import("../pages/calendar"));

// templates
const AllTemplates = React.lazy(() => import("../pages/templates/index"));
const SelectedTemplate = React.lazy(() => import("../pages/templates/selectedTemplate/SelectedTemplate"));

// pages
// const Starter = React.lazy(() => import('../pages/Starter'));
// const Profile = React.lazy(() => import('../pages/profile'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
	<Route
		{...rest}
		render={(props) => {
			// const loggedInUser = getLoggedInUser();
			// // check if route is restricted by role
			// if (roles && roles.indexOf(loggedInUser.role) === -1) {
			// 	// role not authorised so redirect to home page
			// 	return <Redirect to={{ pathname: "/" }} />;
			// }

			// authorised so return component
			return <Component {...props} />;
		}}
	/>
);

// root routes
const rootRoute = {
	path: "/",
	exact: true,
	component: () => <Redirect to="/jobs/all" />,
	route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
	path: "/dashboard",
	name: "Dashboards",
	icon: "uil-home-alt",
	header: "Navigation",
	children: [
		// {
		//     path: '/dashboard/analytics',
		//     name: 'Analytics',
		//     component: AnalyticsDashboard,
		//     route: PrivateRoute,
		// },
		// {
		//     path: '/dashboard/crm',
		//     name: 'CRM',
		//     component: CRMDashboard,
		//     route: PrivateRoute,
		// },
		{
			path: "/dashboard/ecommerce",
			name: "Ecommerce",
			badge: {
				variant: "success",
				text: "3",
			},
			component: EcommerceDashboard,
			route: PrivateRoute,
		},
		// {
		//     path: '/dashboard/project',
		//     name: 'Project',
		//     component: ProjectDashboard,
		//     route: PrivateRoute,
		// },
	],
};

const jobRoutes = {
	path: "/jobs/all",
	name: "Projects",
	icon: "uil-building",
	header: "",
	children: [
		{
			path: "/jobs/all",
			name: "All Projects",
			component: AllJobs,
			route: PrivateRoute,
		},
		{
			path: "/jobs/:jobId/edit",
			name: "",
			component: EditJob,
			route: PrivateRoute,
		},
		{
			path: "/jobs/:jobId",
			name: "",
			component: SelectedJob,
			route: PrivateRoute,
		},
	],
};

const adminRoutes = {
	path: "/admin",
	name: "Admin",
	icon: "uil-shield",
	role: [UserRoles.ADMIN],
	header: "",
	collapse: true,
	children: [
		{
			path: "/admin",
			name: "Users",
			component: Admin,
			route: PrivateRoute,
			icon: "uil-user",
		},
		{
			path: pathConstants.dashboard.location,
			name: "",
			component: LocationProfile,
			route: PrivateRoute,
		},
		{
			path: pathConstants.dashboard.edit,
			name: "",
			component: StateProfile,
			route: PrivateRoute,
		},
		{
			path: pathConstants.dashboard.root,
			name: "Dashboards",
			component: StateList,
			route: PrivateRoute,
			icon: "uil-dashboard",
		},
		{
			path: pathConstants.test.root,
			name: "Test",
			component: Test,
			route: PrivateRoute,
		},
		{
			path: pathConstants.templates.root,
			name: "Templates",
			component: AllTemplates,
			route: PrivateRoute,
		},
	],
	// Admin
};

const templateRoutes = {
	path: "/templates/all",
	header: "",
	children: [
		{
			path: pathConstants.templates.root,
			name: "Templates",
			component: AllTemplates,
			route: PrivateRoute,
		},
		{
			path: pathConstants.templates.oneItem,
			name: "Selected Template",
			component: SelectedTemplate,
			route: PrivateRoute,
		},
	],
};

const userProfileRoutes = {
	path: pathConstants.userProfile.root,
	icon: "uil-user",
	header: "",
	children: [
		// {
		//     path: pathConstants.userProfile.root,
		//     name: 'User Profile',
		//     component: EditUserProfile,
		//     route: PrivateRoute,
		// },
		{
			path: pathConstants.userProfile.edit,
			name: "User Profile Edit",
			component: EditUserProfile,
			route: PrivateRoute,
		},
		{
			path: pathConstants.userProfile.notifications,
			name: "Notifications",
			component: UserNotifications,
			route: PrivateRoute,
		},
	],
};

const conversationsRoutes = {
	path: pathConstants.conversations.all,
	icon: "uil-user",
	children: [
		{
			path: pathConstants.conversations.all,
			name: "Conversations",
			component: Conversations,
			route: PrivateRoute,
		},
	],
};

const poRoutes = {
	path: "/purchaseorders/all",
	name: "Purchase Orders",
	icon: "uil-newspaper",
	header: "",
	children: [
		{
			path: "/purchaseorders/all",
			name: "All POs",
			component: AllPurchaseOrders,
			route: PrivateRoute,
		},
		{
			path: "/purchaseorders/:purchaseOrderId/edit",
			name: "",
			component: EditPurchaseOrder,
			route: PrivateRoute,
		},
		{
			path: "/purchaseorders/:purchaseOrderId",
			name: "",
			component: SelectedPurchaseOrder,
			route: PrivateRoute,
		},
	],
};

const inventoryItemRoutes = {
	path: "/items/all",
	name: "Inventory Items",
	icon: "uil-screw",
	header: "",
	children: [
		{
			path: "/items/all",
			name: "All Inventory Items",
			component: AllInventoryItems,
			route: PrivateRoute,
		},
		{
			path: "/items/:itemId/edit",
			name: "",
			component: EditItem,
			route: PrivateRoute,
		},
		{
			path: "/items/:itemId",
			name: "",
			component: SelectedItem,
			route: PrivateRoute,
		},
	],
};

const invAdjRoutes = {
	path: "/inventoryadjustments/all",
	name: "Inventory Adjustments",
	icon: "uil-truck-loading",
	header: "",
	component: AllInventoryAdjustments,
	route: PrivateRoute,
	// children: [
	//     {
	//         path: '/inventoryadjustments/all',
	//         name: 'All Inventory Adjustments',
	//         component: AllInventoryAdjustments,
	//         route: PrivateRoute,
	//     },
	// ]
};

const calendarRoutes = {
	path: "/calendar",
	name: "Calendar",
	icon: "uil-calendar-alt",
	header: "",
	component: Calendar,
	route: PrivateRoute,
	// children: [
	//     {
	//         path: '/calendar',
	//         name: 'Calendar',
	//         component: Calendar,
	//         route: PrivateRoute,
	//     }
	// ]
};

//hide on prod
const itemBankRoutes = {
	path: pathConstants.itemBank.all,
	name: "Item Bank",
	icon: "uil-screw",
	header: "",
	role: [UserRoles.ADMIN, UserRoles.BILLING_ADMIN, UserRoles.PURCHASE_AGENT, UserRoles.PROJECT_MANAGER],
	children: [
		{
			path: pathConstants.itemBank.all,
			name: "All Item Bank Items",
			component: ItemBank,
			route: PrivateRoute,
		},
		{
			path: pathConstants.itemBank.edit,
			name: "",
			component: EditItemBankItem,
			route: PrivateRoute,
		},
		{
			path: pathConstants.itemBank.variant,
			name: "variant23",
			component: SelectedItemBankItem,
			route: PrivateRoute,
		},
		{
			path: pathConstants.itemBank.selectedItem,
			name: "",
			component: SelectedItemBankItem,
			route: PrivateRoute,
		},
	],
};

const vendorRoutes = {
	path: pathConstants.vendor.root,
	icon: "uil-screw",
	header: "",
	hidden: true,
	children: [
		{
			path: pathConstants.vendor.profile,
			name: "Vendor Profile",
			component: VendorProfile,
			route: PrivateRoute,
		},
	],
};

const manufacturerRoutes = {
	path: pathConstants.manufacturer.root,
	icon: "uil-screw",
	header: "",
	hidden: true,
	children: [
		{
			path: pathConstants.manufacturer.profile,
			name: "Manufacturer Profile",
			component: ManufacturerProfile,
			route: PrivateRoute,
		},
	],
};

const materialRoutes = {
	path: pathConstants.material.root,
	icon: "uil-screw",
	header: "",
	hidden: true,
	children: [
		{
			path: pathConstants.material.profile,
			name: "Material Profile",
			component: MaterialProfile,
			route: PrivateRoute,
		},
	],
};

const sharedRoutes = {
	path: "/shared",
	hidden: true,
	children: [
		{
			path: pathConstants.share.jobs.all,
			name: "Shared Jobs",
			component: AllJobs,
			route: Route,
		},
		{
			path: pathConstants.share.jobs.oneJob,
			name: "Shared Job",
			component: SelectedJob,
			route: Route,
		},
		{
			path: pathConstants.share.purchaseOrders.all,
			name: "Shared Purchase Orders",
			component: AllPurchaseOrders,
			route: Route,
		},
		{
			path: pathConstants.share.purchaseOrders.oneItem,
			name: "Shared Purchase Orders",
			component: SelectedPurchaseOrder,
			route: Route,
		},
		{
			path: pathConstants.share.inventoryItems.all,
			name: "Shared Inventory Items",
			component: AllInventoryItems,
			route: Route,
		},
		{
			path: pathConstants.share.inventoryItems.oneItem,
			name: "Shared Inventory Item",
			component: SelectedItem,
			route: Route,
		},
	],
};

const appRoutes = [
	materialRoutes,
	manufacturerRoutes,
	jobRoutes,
	poRoutes,
	inventoryItemRoutes,
	invAdjRoutes,
	calendarRoutes,
	itemBankRoutes,
	adminRoutes,
	vendorRoutes,
	userProfileRoutes,
	conversationsRoutes,
	sharedRoutes,
	templateRoutes,
];

// pages
// const pageRoutes = {
//     path: '/pages',
//     name: 'Pages',
//     icon: 'uil-copy-alt',
//     header: '',
//     children: [
//         {
//             path: '/pages/starter',
//             name: 'Starter',
//             component: Starter,
//             route: PrivateRoute,
//         },
//         {
//             path: '/pages/profile',
//             name: 'Profile',
//             component: Profile,
//             route: PrivateRoute,
//         },

//     ],
// };

// auth
const authRoutes = {
	path: "/account",
	name: "Auth",
	children: [
		{
			path: "/account/login",
			name: "Login",
			component: Login,
			route: Route,
		},
		{
			path: "/account/logout",
			name: "Logout",
			component: Logout,
			route: Route,
		},
		{
			path: "/account/register",
			name: "Register",
			component: Register,
			route: Route,
		},
	],
};

// ui
// const uiRoutes = {
//     path: '/ui',
//     name: 'Components',
//     icon: 'uil-package',
//     header: 'UI Elements',
//     children: [
//         // {
//         //     path: '/ui/base',
//         //     name: 'Base UI',
//         //     children: [
//         //         {
//         //             path: '/ui/accordions',
//         //             name: 'Accordions',
//         //             component: Accordions,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/alerts',
//         //             name: 'Alerts',
//         //             component: Alerts,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/badges',
//         //             name: 'Badges',
//         //             component: Badges,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/buttons',
//         //             name: 'Buttons',
//         //             component: Buttons,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/cards',
//         //             name: 'Cards',
//         //             component: Cards,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/carousel',
//         //             name: 'Carousel',
//         //             component: Carousel,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/dropdowns',
//         //             name: 'Dropdowns',
//         //             component: Dropdowns,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/grid',
//         //             name: 'Grid',
//         //             component: Grid,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/listgroups',
//         //             name: 'List Groups',
//         //             component: ListGroups,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/modals',
//         //             name: 'Modals',
//         //             component: Modals,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/popovers',
//         //             name: 'Popovers',
//         //             component: Popovers,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/progress',
//         //             name: 'Progress',
//         //             component: Progress,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/ribbons',
//         //             name: 'Ribbons',
//         //             component: Ribbons,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/spinners',
//         //             name: 'Spinners',
//         //             component: Spinners,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/tabs',
//         //             name: 'Tabs',
//         //             component: Tabs,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/toasts',
//         //             name: 'Toasts',
//         //             component: Toasts,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/tooltips',
//         //             name: 'Tooltips',
//         //             component: Tooltips,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/typography',
//         //             name: 'Typography',
//         //             component: Typography,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/widgets',
//         //             name: 'Widgets',
//         //             component: Widgets,
//         //             route: PrivateRoute,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/extended',
//         //     name: 'Extended UI',
//         //     children: [
//         //         {
//         //             path: '/ui/dragdrop',
//         //             name: 'Drag and Drop',
//         //             component: DragDrop,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/rangesliders',
//         //             name: 'Range Sliders',
//         //             component: RangeSliders,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/ratings',
//         //             name: 'Ratings',
//         //             component: Ratings,
//         //             route: PrivateRoute,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/icons',
//         //     name: 'Icons',
//         //     children: [
//         //         {
//         //             path: '/ui/icons/dripicons',
//         //             name: 'Dripicons',
//         //             component: Dripicons,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/icons/mdi',
//         //             name: 'Material Design',
//         //             component: MDIIcons,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/icons/unicons',
//         //             name: 'Unicons',
//         //             component: Unicons,
//         //             route: Unicons,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/forms',
//         //     name: 'Forms',
//         //     children: [
//         //         {
//         //             path: '/ui/forms/basic',
//         //             name: 'Basic Elements',
//         //             component: BasicForms,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/forms/advanced',
//         //             name: 'Form Advanced',
//         //             component: FormAdvanced,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/forms/validation',
//         //             name: 'Form Validation',
//         //             component: FormValidation,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/forms/wizard',
//         //             name: 'Form Wizard',
//         //             component: FormWizard,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/forms/upload',
//         //             name: 'File Upload',
//         //             component: FileUpload,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/forms/editors',
//         //             name: 'Editors',
//         //             component: Editors,
//         //             route: PrivateRoute,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/charts',
//         //     name: 'Charts',
//         //     children: [
//         //         {
//         //             path: '/ui/charts/apex',
//         //             name: 'Apex',
//         //             component: ApexChart,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/charts/brite',
//         //             name: 'Brite',
//         //             component: BriteChart,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/charts/chartjs',
//         //             name: 'Chartjs',
//         //             component: ChartJs,
//         //             route: PrivateRoute,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/tables',
//         //     name: 'Tables',
//         //     children: [
//         //         {
//         //             path: '/ui/tables/basic',
//         //             name: 'Basic',
//         //             component: BasicTables,
//         //             route: PrivateRoute,
//         //         },
//         //         {
//         //             path: '/ui/tables/advanced',
//         //             name: 'Advanced',
//         //             component: AdvancedTables,
//         //             route: PrivateRoute,
//         //         },
//         //     ],
//         // },
//         // {
//         //     path: '/ui/googlemaps',
//         //     name: 'Google Maps',
//         //     component: GoogleMaps,
//         //     route: PrivateRoute,
//         // },
//     ],
// };

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
	let flatRoutes = [];

	routes = routes || [];
	routes.forEach((item) => {
		flatRoutes.push(item);

		if (typeof item.children !== "undefined") {
			flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
		}
	});
	return flatRoutes;
};

// All routes
const allRoutes = [rootRoute, dashboardRoutes, ...appRoutes, authRoutes];

const authProtectedRoutes = [...appRoutes];
// const authProtectedRoutes = [dashboardRoutes, ...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
