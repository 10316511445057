import React, { useState } from "react";
import { Card } from "reactstrap";
import { itemBankColors } from "../../../constants";
import { formatItemSizeText } from "../../../helpers/columnFormatters";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { defaultItemBankFieldOrder, itemBankApiModel } from "../../../models/itemBankApiModel";
import { Link } from "react-router-dom";

export const getItemTextPreview = (itemObj, showManufacturerAndVendor = false, displayFinishes = false, separator = ", ", displayFinishCode = false) => {
	if (itemObj?.associated_item_bank) {
		let item = itemObj.associated_item_bank;

		if (itemObj.variant) {
			item = itemObj.variant;
			item.manufacturer = itemObj.associated_item_bank.manufacturer;
			item.descriptions = itemObj.associated_item_bank.descriptions;
			item.colors = item.color ? [item.color] : [];
			item.sub_descriptions = item.sub_description ? [item.sub_description] : [];
			item.vendors = item.vendor ? [item.vendor] : [];
			item.finishes = item.finish ? [item.finish] : [];
			item.sizes = item.size ? [item.size] : [];
			item.prices = item.price ? [item.price] : [];
		}
		if (displayFinishCode) {
			item.finish_code = itemObj.finish_code;
		}

		const defaultOrder = item.previewOrder || defaultItemBankFieldOrder;

		let result = `${showManufacturerAndVendor ? `${item.vendors[0]?.vendor?.name} ${item?.manufacturer?.name} ` : ""}${defaultOrder
			.map((fieldName) => {
				if (fieldName === "colors") {
					const hasColors = (item.colors || []).find((color) => color?.code != null || color?.name != null);
					return hasColors ? `COLOR: ${hasColors?.code} ${hasColors?.name}` : null;
				}
				if (fieldName === "vendor_codes") {
					return item.vendor_codes;
				}
				if (fieldName === "manufacturer") {
					return item.manufacturer;
				}
				if (fieldName === "manufacturer_codes") {
					return item.manufacturer_code;
				}
				if (fieldName === "descriptions") {
					if (Array.isArray(item.descriptions) && item.descriptions.length > 0) {
						const hasDescription = (item.descriptions || []).find((desc) => desc?.value != null);
						if (hasDescription) {
							return hasDescription?.value;
						}
					} else {
						return `${item.descriptions}`;
					}
				}
				if (fieldName === "sub_descriptions") {
					const hasSubDescription = (item.sub_descriptions || []).find((desc) => desc?.code != null || desc.description != null);
					return hasSubDescription ? `${`${hasSubDescription?.code} ` || ""}${hasSubDescription.description || ""}` : "";
				}
				if (fieldName === "custom_fields") {
					return item.custom_fields ? `${item.custom_fields.length} custom fields` : "";
				}
				if (fieldName === "sizes") {
					const sizeText = formatItemSizeText(item.size);
					return sizeText.length > 0 ? `SIZE: ${sizeText}` : "";
				}
				if (fieldName === "uom") {
					return `${item.uom || ""}`;
				}
				if (fieldName === "material") {
					return `${item.material ? item.material : ""}`;
				}
				if (fieldName === "custom_field") {
					return `${item.custom_field ? item.custom_field?.name + " " + item.custom_field?.value : ""}`;
				}
				if (fieldName === "finishes") {
					if (displayFinishes) {
						let result = (item.finishes || []).join(", ");
						if (result.length > 0) {
							result = `FINISH: ${result}`;
						}
						return result;
					}
					return item.finishes ? `${item.finishes.length} finish variants` : null;
				}
				if (displayFinishCode && fieldName === "finish_code" && item.finish_code) {
					return `FINISH CODE: ${item.finish_code}`;
				}
				return "";
			})
			.filter((label) => label && label.length > 0)
			.join(separator)}`;

		result.replace(/\t/g, "");

		if (result.length === 0) {
			return "-";
		}

		return result;
	}

	let result = (itemObj?.item_code || "").replace(/"/g, '""').replace(/\n/g, "");

	if (result.length === 0) {
		result = "-";
	}

	return result;
};

export const getItemTextPreviewCSV = (itemObj, showManufacturerAndVendor = false) => {
	if (itemObj.associated_item_bank) {
		let item = itemObj.associated_item_bank;

		if (itemObj.variant) {
			item = itemObj.variant;
			item.manufacturer = itemObj.associated_item_bank?.manufacturer?.name;
			item.descriptions = itemObj.associated_item_bank?.descriptions;
			item.colors = item.color ? [item.color] : [];
			item.sub_descriptions = item.sub_description ? [item.sub_description] : [];
			item.vendors = item.vendor ? [item.vendor] : [];
			item.finishes = item.finish ? [item.finish] : [];
			item.sizes = item.size ? [item.size] : [];
			item.prices = item.price ? [item.price] : [];
		}

		const defaultOrder = item.previewOrder ? item.previewOrder : defaultItemBankFieldOrder;

		return `${showManufacturerAndVendor ? `${item.vendors[0]?.vendor?.name} ${item?.manufacturer?.name} ` : ""}${defaultOrder
			.map((fieldName) => {
				if (fieldName === "colors") {
					const hasColors = (item.colors || []).find((color) => color?.code != null || color?.name != null);
					return hasColors ? `COLOR: ${hasColors?.code} ${hasColors?.name}` : null;
				}
				if (fieldName === "vendor_codes" && item.vendor_codes) {
					return `VENDOR CODES: ${item.vendor_codes}`;
				}
				if (fieldName === "manufacturer" && item.manufacturer) {
					return `MANUFACTURER: ${item.manufacturer}`;
				}
				if (fieldName === "manufacturer_codes" && item.manufacturer_code) {
					return `MANUFACTURER CODE: ${item.manufacturer_code}`;
				}
				if (fieldName === "descriptions") {
					if (Array.isArray(item.descriptions) && item.descriptions.length > 0) {
						const hasDescription = (item.colors || []).find((desc) => desc?.value != null);
						if (hasDescription) {
							return hasDescription?.value;
						}
					} else if (item.descriptions) {
						return `${item.descriptions}`;
					}
				}
				if (fieldName === "sub_descriptions") {
					const hasSubDescription = (item.sub_descriptions || []).find((desc) => desc?.code != null || desc.description != null);
					return hasSubDescription ? `${`${hasSubDescription?.code} ` || ""}${hasSubDescription.description || ""}` : "";
				}
				if (fieldName === "custom_fields") {
					return item.custom_fields ? `${item.custom_fields.length} custom fields` : "";
				}
				if (fieldName === "sizes") {
					const sizeText = formatItemSizeText(item.size);
					return sizeText.length > 0 ? `SIZE: ${sizeText}` : "";
				}
				if (fieldName === "uom") {
					return item.uom.length > 0 ? item.uom : "";
				}
				if (fieldName === "material") {
					return `${item.material ? `MATERIAL: ${item.material}` : ""}`;
				}
				if (fieldName === "custom_field") {
					return `${item.custom_field ? `CUSTOM FIELD: ${item.custom_field}` : ""}`;
				}
				if (fieldName === "finishes") {
					return item.finishes?.length > 0 ? `FINISH: ${item.finishes[0]}` : "";
				}
				return "";
			})
			.filter((label) => label && label.length > 0)
			.join(", ")
			.replace(/"/g, '""')}`;
	}

	return `${itemObj.item_code ? itemObj.item_code.replace(/"/g, '""').replace(/\n/g, "") : ""}`;
};

const ItemPreview = ({
	maxWidth = undefined,
	handleOnClick,
	data,
	previewOrder = undefined,
	onOrderChange = undefined,
	simple,
	showFieldLabel = false,
	showVendor = false,
	showVendorCode = false,
	hiddenFields = [],
	draggable = false,
	white = false,
	linkTo = undefined,
	showFinishCode = false,
}) => {
	const [showPopup, setShowPopup] = useState(false);
	const [items, setItems] = useState(
		Array.isArray(previewOrder) && previewOrder.length > 0
			? previewOrder.filter((field) => !hiddenFields.includes(field))
			: defaultItemBankFieldOrder.filter((field) => !hiddenFields.includes(field)),
	);

	const onClick = () => {
		handleOnClick(data);
	};
	if (data == null) {
		return <></>;
	}

	let {
		manufacturer,
		descriptions,
		colors,
		vendor_codes,
		manufacturer_codes,
		sub_descriptions,
		sizes,
		finishes,
		finish_code,
		custom_fields,
		description,
		vendors,
		custom_field,
	} = data;

	descriptions = descriptions?.filter(({ value }) => value !== "");

	const getStyle = (color) => {
		if (white) {
			return {
				color: "white",
				border: simple ? undefined : `1px solid white`,
			};
		} else {
			return {
				color,
				border: simple ? undefined : `1px solid ${color}`,
			};
		}
	};

	const SortableItem = SortableElement(({ fieldName, itemIndex, length }) => {
		const baseStyle = {
			background: simple ? undefined : "#fff",
			textTransform: "capitalize",
			zIndex: simple ? undefined : 10000000,
			whiteSpace: "no-wrap",
			padding: simple ? undefined : 5,
			color: "#727cf5",
			border: simple ? undefined : "1px solid #727cf5",
			borderRadius: simple ? undefined : "10px",
			margin: "3px",
			textAlign: "left",
		};

		if (fieldName === "descriptions") {
			const hasDescription = (descriptions || []).find(({ value }) => value);
			if (hasDescription) {
				return (
					<div
						style={{
							...baseStyle,
							...getStyle(itemBankColors.description),
							whiteSpace: "pre-wrap",
						}}>
						{`${hasDescription?.value}`}
						{itemIndex < length - 1 ? "," : ""}
					</div>
				);
			}
		}

		if (fieldName === "description" && description && description !== "" && description.length > 0) {
			return (
				<div
					style={{
						...baseStyle,
						...getStyle(itemBankColors.description),
						whiteSpace: "pre-wrap",
					}}>
					{`${description}`}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}

		if (fieldName === "custom_field" && custom_field && custom_field.name && custom_field.value) {
			return (
				<div
					style={{
						...baseStyle,
						...getStyle(itemBankColors.customField),
					}}>
					{`${custom_field?.name} ${custom_field?.value}`}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}

		if (fieldName === "colors" && colors.length > 0) {
			const hasColors = (colors || []).find(({ code, name }) => code != null || name != null);
			return (
				<div
					style={{
						...baseStyle,
						...getStyle(itemBankColors.color),
					}}>
					{showFieldLabel ? "COLOR: " : ""}
					{`${hasColors?.code ? `${hasColors?.code} ` : ""}${hasColors?.name || ""}`}
					{colors.length > 1 ? ` (${colors.length - 1} more)` : ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (fieldName === "manufacturer_codes" && manufacturer_codes.length > 0) {
			const hasManufacturerCode = manufacturer_codes.find((code) => code != null);
			return (
				<div
					style={{
						...baseStyle,
						...getStyle(itemBankColors.manufacturerCode),
					}}>
					{hasManufacturerCode}
					{manufacturer_codes.length > 1 ? ` (${manufacturer_codes.length - 1} more)` : ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (fieldName === "manufacturer" && manufacturer?.name != null) {
			return (
				<div
					style={{
						...baseStyle,
					}}>
					{manufacturer?.name}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (fieldName === "custom_fields" && custom_fields && custom_fields.length > 0) {
			const hasCustomFields = (custom_fields || []).find(({ name, value }) => name != null || value != null);
			if (hasCustomFields) {
				return (
					<div
						style={{
							...baseStyle,
							...getStyle(itemBankColors.customFields),
						}}>
						{`${hasCustomFields?.name ? `${hasCustomFields?.name} ` : ""}${hasCustomFields?.value || ""}`}
						{custom_fields.length > 1 ? ` (${custom_fields.length - 1} more)` : ""}
						{itemIndex < length - 1 ? "," : ""}
					</div>
				);
			}
		}

		if (fieldName === "sub_descriptions" && sub_descriptions) {
			const hasSubDescriptions = (sub_descriptions || []).find(({ code, description }) => code != null || description != null);
			if (hasSubDescriptions) {
				return (
					<div
						style={{
							...baseStyle,
							...getStyle(itemBankColors.subDescription),
						}}>
						{`${hasSubDescriptions?.code ? `${hasSubDescriptions?.code} ` : ""}${hasSubDescriptions.description || ""}`}
						{sub_descriptions.length > 1 ? ` (${sub_descriptions.length - 1} more)` : ""}
						{itemIndex < length - 1 ? "," : ""}
					</div>
				);
			}
		}
		const filteredSizes = sizes.filter((size) => {
			const keys = Object.keys(size);
			return !(keys.includes("custom_item") && keys.length === 1);
		});
		if (fieldName === "sizes" && filteredSizes.length > 0) {
			return (
				<div style={{ ...baseStyle, ...getStyle(itemBankColors.size) }}>
					{showFieldLabel ? "SIZE: " : ""}
					{`${formatItemSizeText(filteredSizes[0])}`}
					{filteredSizes.length > 1 ? ` (${filteredSizes.length - 1} more)` : ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (fieldName === "finishes" && finishes.length > 0) {
			return (
				<div style={{ ...baseStyle, ...getStyle(itemBankColors.finish) }}>
					{showFieldLabel ? "FINISH: " : ""}
					{finishes[0] || ""}
					{colors.length > 1 ? ` (${finishes.length - 1} more)` : ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (fieldName === "finish_code" && showFinishCode && finish_code) {
			return (
				<div style={{ ...baseStyle, ...getStyle(itemBankColors.finish) }}>
					{showFieldLabel ? "FINISH CODE: " : ""}
					{finish_code || ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (showVendor && fieldName === "vendors" && vendors != null && !Array.isArray(vendors)) {
			return (
				<div style={{ ...baseStyle, ...getStyle(itemBankColors.vendorCode) }}>
					{showFieldLabel ? "VENDOR: " : ""}
					{vendors?.name || ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		if (showVendorCode && fieldName === "vendor_codes" && vendor_codes.length > 0) {
			return (
				<div style={{ ...baseStyle, ...getStyle(itemBankColors.vendorCode) }}>
					{showFieldLabel ? "VENDOR CODE: " : ""}
					{vendor_codes[0]?.code || ""}
					{itemIndex < length - 1 ? "," : ""}
				</div>
			);
		}
		return <div></div>;
	});

	const SortableList = SortableContainer(({ itemsList }) => {
		return (
			<div
				className="text-center capitalize"
				style={{
					display: "flex",
					flexWrap: maxWidth ? "wrap" : undefined,
				}}>
				{itemsList.map((item, index) => (
					<SortableItem key={`item-${index}`} index={index} itemIndex={index} length={itemsList.length} fieldName={item} />
				))}
			</div>
		);
	});

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const result = arrayMoveImmutable(items, oldIndex, newIndex);
		setItems(result);
		if (onOrderChange) {
			onOrderChange(result);
		}
	};

	if (JSON.stringify(itemBankApiModel) === JSON.stringify(data)) {
		return <div>There's no available preview yet!</div>;
	}

	const list = <SortableList itemsList={items} onSortEnd={onSortEnd} axis={"x"} />;

	const vendorDescriptions = descriptions.filter(({ isVendor }) => isVendor).map(({ value }) => value);

	const content = simple ? (
		<div
			style={{
				position: "relative",
				maxWidth: maxWidth,
			}}>
			<div
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					background: "transparent",
					zIndex: draggable ? -1 : simple ? 0 : 100000000,
					height: "100%",
					width: "100%",
				}}
			/>
			{list}
		</div>
	) : (
		<Card
			body
			onClick={onClick}
			style={{
				border: "1px solid blue",
				borderRadius: "10px",
				overflow: "auto",
				cursor: "pointer",
			}}>
			{list}
		</Card>
	);

	return (
		<div className="item-preview">
			{linkTo ? <Link to={linkTo}>{content}</Link> : <>{content}</>}
			{vendorDescriptions.length > 0 && (
				<span className={"popup"}>
					<i
						className="mdi mdi-information"
						onClick={(event) => {
							event.stopPropagation();
							setShowPopup(!showPopup);
						}}
					/>
					<div className={`content${showPopup ? " active" : ""}`}>
						<div>
							<h6>Vendor description</h6>
							{vendorDescriptions.join(",")}
						</div>
						<i
							className="mdi mdi-close"
							onClick={(event) => {
								event.stopPropagation();
								setShowPopup(false);
							}}
						/>
					</div>
				</span>
			)}
		</div>
	);
};

export default ItemPreview;
