import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import AuthProvider from "./context/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { BUGSNAG } from "./config";

Bugsnag.start({
	apiKey: BUGSNAG,
	plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
	<ErrorBoundary>
		<BrowserRouter>
			<Provider store={configureStore()}>
				<AuthProvider>
					<ToastContainer
						position="top-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
					/>
					<App />
				</AuthProvider>
			</Provider>
		</BrowserRouter>
	</ErrorBoundary>,
	document.getElementById("root"),
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: http://bitly./CRA-PWA
serviceWorker.unregister();
