// @flow
import React, { Component, FC, PropsWithChildren, Suspense, useEffect } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import { changeSidebarTheme, changeSidebarType, LayoutAction } from "../redux/actions";
import * as layoutConstants from "../constants/layout";
import ThemeCustomizer from "../components/ThemeCustomizer";
import LayoutLoading from "./LayoutLoading";
import LayoutEmptyLoading from "./LayoutEmptyLoading";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const LeftSidebar = React.lazy(() => import("../components/LeftSidebar"));
const Topbar = React.lazy(() => import("../components/Topbar"));
const Footer = React.lazy(() => import("../components/Footer"));
const RightSidebar = React.lazy(() => import("../components/RightSidebar"));

interface VerticalLayoutProps extends PropsWithChildren {
	changeSidebarType: (sidebarType: string) => LayoutAction;
	changeSidebarTheme: (theme: string) => LayoutAction;
	layout: any;
}

const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
	const { children, layout, changeSidebarType, changeSidebarTheme } = props;
	const isCondensed = layout.leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
	const isLight = layout.leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT;

	/**
	 * Opens the left menu - mobile
	 */
	const openLeftMenu = () => {
		if (document.body) document.body.classList.add("sidebar-enable");
	};

	/**
	 * Handles the menu on window resizes
	 */
	const updateDimensions = () => {
		// activate the condensed sidebar if smaller devices like ipad or tablet
		if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
			changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
		} else if (window.innerWidth > 1028) {
			changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED);
		}
	};

	useEffect(() => {
		window.addEventListener("resize", updateDimensions);

		changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_DARK);

		// activate the condensed sidebar if smaller devices like ipad or tablet
		if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
			changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
		}
		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	}, []);

	return (
		<div className="app">
			<div className="wrapper">
				<Suspense fallback={<LayoutEmptyLoading />}>
					<LeftSidebar {...props} isCondensed={isCondensed} isLight={isLight} hideUserProfile />
				</Suspense>

				<div className="content-page">
					<div className="content">
						<Suspense fallback={<LayoutEmptyLoading />}>
							<Topbar {...props} openLeftMenuCallBack={openLeftMenu} hideLogo />
						</Suspense>

						<Container fluid>
							<Suspense fallback={<LayoutLoading />}>{children}</Suspense>
						</Container>
					</div>

					<Suspense fallback={<LayoutEmptyLoading />}>
						<Footer />
					</Suspense>
				</div>
			</div>

			<Suspense fallback={<LayoutEmptyLoading />}>
				<RightSidebar {...props}>
					<ThemeCustomizer />
				</RightSidebar>
			</Suspense>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};
export default connect(mapStateToProps, { changeSidebarTheme, changeSidebarType })(VerticalLayout);
