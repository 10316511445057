import React from "react";
import { Label } from "reactstrap";

interface ItemBankLabelProps {
	label: string;
	isRequired?: boolean;
	color?: string;
}

const ItemBankLabel = ({ label, isRequired, color }: ItemBankLabelProps) => {
	return (
		<Label for={label.toLowerCase()} className={`${color ? "" : "text-primary"}`} style={{ color: `${color}`, whiteSpace: "pre-wrap", textarea: "left" }}>
			{label}
			{isRequired ? <span style={{ color: "red" }}>*</span> : null}
		</Label>
	);
};

export default ItemBankLabel;
