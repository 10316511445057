export const IMS_ENV = process.env.REACT_APP_ENV || "development";
export const API_KEY = process.env.REACT_APP_API_KEY || "AIzaSyCAzFRODA-NhKX1HOi0dgq4ZfroyuCXIIg";
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || "ims-dev-56c8c.firebaseapp.com";
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || "ims-dev-56c8c";
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET || "ims-dev-56c8c.appspot.com";
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID || "587244816483";
export const APP_ID = process.env.REACT_APP_APP_ID || "1:587244816483:web:b1df0198c8857210711056";
export const SERVICE_WORKER = process.env.REACT_APP_SERVICE_WORKER || "firebase-messaging-sw-dev.js";
export const BUGSNAG = process.env.REACT_APP_BUGSNAG || "1e18f095b1eacb1b7361613d738a771c";
export const API_HOST = process.env.REACT_APP_API_HOST || "http://localhost:8080";
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || "http://localhost:3000";
