// @ts-nocheck

export function convertArrayOfObjectsToCSV(array, columnName, ignoreFields = []) {
	let result = "";
	const columnDelimiter = ",";
	const lineDelimiter = "\n";

	let keys = new Set();
	array.forEach((item) => {
		const tempKeys = Object.keys(item);
		tempKeys.forEach((key) => keys.add(key));
	});

	keys = [...keys];
	keys = keys.filter((key) => !ignoreFields.includes(key));
	let columns = keys.map((key) => {
		return columnName[key];
	});

	result += columns.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach((item) => {
		let ctr = 0;
		keys.forEach((key) => {
			if (ctr > 0) {
				result += columnDelimiter;
			}

			let fieldText = "-";

			if (item[key] != null && item[key] !== "") {
				fieldText = `${item[key]}`;
			}

			result += fieldText.replace(/\r|\n|\t/g, " ").replace(/,/g, "");
			ctr++;
		});
		result += lineDelimiter;
	});

	return result;
}

export function downloadCSV(array, columnName, ignoreFields = [], fileName = "export.csv") {
	const link = document.createElement("a");
	let csv = convertArrayOfObjectsToCSV(array, columnName, ignoreFields);
	if (csv == null) return;

	const filename = fileName;

	if (!csv.match(/^data:text\/csv/i)) {
		csv = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;
	}

	link.setAttribute("href", csv);
	link.setAttribute("download", filename);
	link.click();
}

export const TABLE_CUSTOM_STYLE = (isTableHeader) => {
	const displayTableHeader = isTableHeader ? "flex" : "none";

	return {
		rows: {
			style: {
				minHeight: "50px",
				fontSize: "14px",
				cursor: "pointer",
			},
		},
		cells: {
			style: {
				padding: "0px 8px",
			},
		},
		headCells: {
			style: {
				fontSize: "15px",
				fontWeight: "bold",
				padding: "0px 8px",
			},
		},
		headRow: {
			style: {
				minHeight: "50px",
				display: displayTableHeader,
			},
		},
	};
};
