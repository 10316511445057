import React, { useMemo } from "react";
import { Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { allFlattenRoutes as routes } from "./index";
import AuthLayout from "../layouts/Auth";
import ShareLayout from "../layouts/Share";
import VerticalLayout from "../layouts/Vertical";
import * as layoutConstants from "../constants/layout";
import HorizontalLayout from "../layouts/Horizontal";
import DetachedLayout from "../layouts/Detached";
import ConversationWidget from "../components/ConversationWidget";

// All layouts/containers

function Routes(props) {
	const { location, layout } = props;
	const Layout = useMemo(() => {
		if (location.pathname.includes("login")) {
			return AuthLayout;
		} else if (location.pathname.includes("share")) {
			return ShareLayout;
		}

		let layoutCls = VerticalLayout;

		switch (layout.layoutType) {
			case layoutConstants.LAYOUT_HORIZONTAL:
				layoutCls = HorizontalLayout;
				break;
			case layoutConstants.LAYOUT_DETACHED:
				layoutCls = DetachedLayout;
				break;
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	}, [location.pathname]);

	return (
		<Layout {...props}>
			<Switch>
				{routes.map((route, index) => {
					return (
						!route.children && (
							<route.route
								key={`route_${index}`}
								path={route.path}
								roles={route.roles}
								exact={route.exact}
								component={route.component}></route.route>
						)
					);
				})}
			</Switch>
			<ConversationWidget />
		</Layout>
	);
}

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default withRouter(connect(mapStateToProps, null)(Routes));
