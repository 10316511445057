import { USER_NOTIFICATIONS, USER_ROLE } from "../constants";
import * as Yup from "yup";

export const getUserRoleForOptions = () => {
	const roleOptions = [];

	Object.keys(USER_ROLE).forEach((key) => {
		roleOptions.push({
			value: USER_ROLE[key].textkey,
			label: USER_ROLE[key].name
		});
	});

	return roleOptions;
};

export const isAdmin = (user) => {
	return user && (user.role === USER_ROLE.ADMIN.textkey || user.role === USER_ROLE.BILLING_ADMIN.textkey || user.role === USER_ROLE.PURCHASE_AGENT.textkey);
};

export const isProjectManager = (user) => {
	return user && user.role === USER_ROLE.PROJECT_MANAGER.textkey;
};

export const isAdminOrPurchasingAgent = (user) => {
	return user?.role === USER_ROLE.ADMIN.textkey || user?.role === USER_ROLE.PURCHASE_AGENT.textkey;
};

export const isPMorSuperintendent = (user) => {
	return user?.role === USER_ROLE.SUPERINTENDENT.textkey || user?.role === USER_ROLE.PROJECT_MANAGER.textkey;
};

export const isProjectManagerOrAdmin = (user) => {
	return user && (user.role === USER_ROLE.PROJECT_MANAGER.textkey || user.role === USER_ROLE.ADMIN.textkey);
};

export const getName = (user) => {
	if (user) {
		if (user.firstname && user.lastname) {
			return `${user.firstname} ${user.lastname}`;
		} else {
			return user.username;
		}
	}

	return "";
};

export const roleNumbers = (role) => {
	switch (role.toLowerCase()) {
		case "viewer":
			return 1;

		case "contributor":
			return 2;

		case "editor":
			return 3;

		case "admin":
			return 4;

		case "owner":
			return 5;

		default:
			return 0;
	}
};

export const properRole = (requiredRole, userRole) => {
	if (roleNumbers(userRole) >= roleNumbers(requiredRole)) {
		return true;
	}
	return false;
};

export const getGeneralNotificationsOptions = (prevNotificationsList, noProject = false) => {
	let notificationsObject = {};
	Object.values(USER_NOTIFICATIONS).forEach((notification) => {
		if (notification.hidden != true) {
			if (!noProject || (noProject && notification.NO_PROJECT !== true)) {
				const previousSetting = (prevNotificationsList || []).find((prevNotification) => prevNotification.notificationKey === notification.key);
				notificationsObject[notification.key] = previousSetting || {
					notificationKey: notification.key,
					phone: true,
					email: true,
					push: true
				};
			}
		}
	});

	// if (prevNotificationsList) {
	// 	list = list.map(elem => {
	// 		const userNotification = prevNotificationsList.find(userElem => userElem.notificationKey === elem.notificationKey);
	// 		return (userNotification) ? {
	// 			...userNotification
	// 		} : elem;
	// 	});
	// }
	return notificationsObject;
};

const phoneRegExp = /\+[0-9]{11}/;

export const userInfoValidationSchema = Yup.object().shape({
	firstname: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
	lastname: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
	username: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
	phone: Yup.string().matches(phoneRegExp, "Invalid phone number!"),
	email: Yup.string().email("Invalid email").required("Required"),
	newPassword: Yup.string().when("isChangePassword", {
		is: true,
		then: (schema) =>
			schema
				.required("This field is required")
				.matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|\\])[a-zA-Z0-9*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|\\]{8,32}$/, {
					message: "Not a valid password"
				}),
		otherwise: (schema) => schema
	}),
	newRePassword: Yup.string().when("isChangePassword", {
		is: true,
		then: (schema) => schema.required("This field is required").oneOf([Yup.ref("newPassword"), null], "Password must match"),
		otherwise: (schema) => schema
	})
});
