import { getItemTextPreview } from "../pages/itemBank/createItem/ItemPreview";

export const getConversationTitle = ({ targetType, target }) => {
	let title = "";
	switch (targetType) {
		case "InventoryAdjustment":
			title = "Inventory Adjustment: " + getItemTextPreview({ ...target.associated_inventory_item });
			break;
		case "InventoryItem":
			title = "Item: " + getItemTextPreview(target);
			break;
		case "ItemBank":
			title = "Item Bank: " + `${target.descriptions?.[0]?.value || ""} ${target.manufacturer?.name || ""}`;
			break;
		case "PurchaseOrder":
			title = `PO: ${target?.po_num || "missing PO number"}`;
			break;
		case "Job":
			title = `Projects: ${target?.name || "missing Job name"} ${target?.job_no || "missing Job number"}`;
	}
	return title;
};

export const getParticipantsFullNames = (participants, currentUserEmail, isShorter = false, maxCount = undefined, exceptEmail = null) => {
	let names = [];
	if (exceptEmail) {
		names = participants.filter(({ email }) => exceptEmail !== email).map(({ firstname, lastname }) => `${firstname || ""} ${lastname || ""}`);
	} else {
		names = participants.map(({ firstname, lastname, email }) =>
			currentUserEmail === email ? "You" : !lastname && !firstname ? email || "" : `${firstname || ""} ${lastname || ""}`,
		);
	}
	if (isShorter) return names.length > maxCount && maxCount ? [...names].splice(0, 3).join(", ") + "..." : names.join(", ");
	return names.join(", ");
};
