// @flow
import React from "react";
import Spinner from "../components/Spinner";
import { Col, Row } from "reactstrap";

const LayoutLoading = () => (
	<Row>
		<Col sm={12}>
			<div className="d-flex justify-content-center">
				<Spinner className="text-primary m-2" type="grow" size={"lg"} />
			</div>
		</Col>
	</Row>
);

export default LayoutLoading;
