export const MOVEMENT_TYPES = {
	IN: "IN",
	OUT: "OUT",
	OTHER: "OTHER",
};

export const ADJUSTMENT_UNIT_TYPES = {
	PALLETS: "PALLETS",
	BOXES: "BOXES",
};
export const ADJUSTMENT_OPTIONS = {
	INVENTORY_ARRIVED_TO_WAREHOUSE: "INVENTORY ARRIVED TO WAREHOUSE",
	RECEIVED_AT_JOBSITE: "RECEIVED AT JOBSITE",
	INVENTORY_ARRIVED_TO_WAREHOUSE_REVERSAL: "INVENTORY ARRIVED TO WAREHOUSE REVERSAL",
	INVENTORY_REQUEST_TO_JOBSITE: "INVENTORY REQUEST TO JOBSITE",
	INVENTORY_FROM_WAREHOUSE_TO_JOBSITE: "INVENTORY FROM WAREHOUSE TO JOBSITE",
	INVENTORY_FROM_WAREHOUSE_TO_JOBSITE_REVERSAL: "INVENTORY FROM WAREHOUSE TO JOBSITE REVERSAL",
	INVENTORY_DIRECTLY_TO_JOBSITE: "INVENTORY DIRECTLY TO JOBSITE",
	INVENTORY_DIRECTLY_TO_JOBSITE_REVERSAL: "INVENTORY DIRECTLY TO JOBSITE REVERSAL",
	INVENTORY_REMOVED_FROM_WAREHOUSE: "INVENTORY REMOVED FROM WAREHOUSE",
	INVENTORY_REMOVED_FROM_WAREHOUSE_REVERSAL: "INVENTORY REMOVED FROM WAREHOUSE REVERSAL",
	RETURN_FROM_JOBSITE: "RETURN FROM JOBSITE",
	ADDED_TO_STORAGE: "ADDED TO STORAGE",
	INVENTORY_MOVED_TO_ANOTHER_PROJECT: "INVENTORY MOVED TO ANOTHER PROJECT",
	TRANSFER_TO_ANOTHER_PROJECT_INSTALL: "TRANSFER TO ANOTHER PROJECT (INSTALL)",
	INVENTORY_MOVED_FROM: "INVENTORY MOVED FROM",
	INVENTORY_MOVED_FROM_INSTALL: "INVENTORY MOVED FROM (INSTALL)",
	REVERSAL: "REVERSAL",
};

export const ADJUSTMENT_OPTIONS_GROUPS = {
	IN: [
		ADJUSTMENT_OPTIONS.INVENTORY_ARRIVED_TO_WAREHOUSE,
		ADJUSTMENT_OPTIONS.RECEIVED_AT_JOBSITE,
		ADJUSTMENT_OPTIONS.INVENTORY_DIRECTLY_TO_JOBSITE,
		ADJUSTMENT_OPTIONS.RETURN_FROM_JOBSITE,
		ADJUSTMENT_OPTIONS.ADDED_TO_STORAGE,
	],
	OUT: [ADJUSTMENT_OPTIONS.INVENTORY_FROM_WAREHOUSE_TO_JOBSITE, ADJUSTMENT_OPTIONS.INVENTORY_REMOVED_FROM_WAREHOUSE],
	OTHER: [
		ADJUSTMENT_OPTIONS.INVENTORY_REQUEST_TO_JOBSITE,
		ADJUSTMENT_OPTIONS.INVENTORY_MOVED_TO_ANOTHER_PROJECT,
		ADJUSTMENT_OPTIONS.TRANSFER_TO_ANOTHER_PROJECT_INSTALL,
		ADJUSTMENT_OPTIONS.REVERSAL,
	],
};
