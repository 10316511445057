import moment from "moment";
import { materialCosts } from "./functions";

const TAX_COST_CALCULATION_DATE = moment("2022-07-13");

export const getTaxCost = (purchaseOrder, costView = 1, ratioNumber = 1) => {
	if (isHomeDepotPo(purchaseOrder)) {
		return (purchaseOrder.invoices || []).reduce((acc, elem) => (acc += elem.taxCost), 0);
	}

	if (purchaseOrder.taxCost !== null) {
		return purchaseOrder.taxCost * ratioNumber;
	}

	if (moment(purchaseOrder.created_date).isBefore(TAX_COST_CALCULATION_DATE)) {
		return purchaseOrder.taxCost * ratioNumber;
	}

	return (materialCosts(purchaseOrder.inventory_items, costView) * getPOTaxRate(purchaseOrder)) / 100;
};

export const getPOTaxRate = (purchaseOrder) => {
	const {
		associated_job: { warehouses },
	} = purchaseOrder;
	let taxRate = 0;

	if (purchaseOrder.taxRate != null) {
		taxRate = purchaseOrder.taxRate;
	} else if (warehouses && warehouses.length > 0 && warehouses[0].taxRate) {
		taxRate = warehouses[0].taxRate;
	}

	return taxRate;
};

export const getInvoiceTotalCosts = ({ freightCost = 0, materialCost = 0, otherCost = 0, taxCost = 0 }) => {
	return freightCost + materialCost + otherCost + taxCost;
};

export const isHomeDepotPo = (po) => {
	return po?.vendorObject?.name === "The Home Depot";
};

export const getInvoicesMaterialCosts = (invoices = []) => {
	return invoices.reduce((sum, invoice) => (sum += invoice.materialCost), 0);
};

export const getInvoicesTaxCosts = (invoices = []) => {
	return invoices.reduce((sum, invoice) => (sum += invoice.taxCost), 0);
};

export const getInvoicesFreightCosts = (invoices = []) => {
	return invoices.reduce((sum, invoice) => (sum += invoice.freightCost), 0);
};

export const getInvoicesOtherCosts = (invoices = []) => {
	return invoices.reduce((sum, invoice) => (sum += invoice.otherCost), 0);
};
