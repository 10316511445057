// @flow
import React, { FC, PropsWithChildren, Suspense, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";

import { changeLayout, LayoutAction } from "../redux/actions";
import ThemeCustomizer from "../components/ThemeCustomizer";
import * as layoutConstants from "../constants/layout";
import LayoutLoading from "./LayoutLoading";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const LeftSidebar = React.lazy(() => import("../components/LeftSidebar"));
const Topbar = React.lazy(() => import("../components/Topbar"));
const Footer = React.lazy(() => import("../components/Footer"));
const RightSidebar = React.lazy(() => import("../components/RightSidebar"));

interface DetachedLayoutProps extends PropsWithChildren {
	changeSidebarType: (sidebarType: string) => LayoutAction;
	changeLayout: (layout: string) => LayoutAction;
	layout: any;
}

const DetachedLayout: FC<DetachedLayoutProps> = (props) => {
	const { children, layout, changeLayout } = props;
	const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

	const isCondensed = layout.leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
	const isLight = layout.leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT;

	useEffect(() => {
		changeLayout(layoutConstants.LAYOUT_DETACHED);
	}, []);

	const openMenu = (e) => {
		e.preventDefault();
		setIsMenuOpened((prevState) => !prevState);
		if (document.body) document.body.classList.add("sidebar-enable");
	};

	return (
		<div className="app">
			<Suspense fallback={<LayoutLoading />}>
				<Topbar {...props} isMenuOpened={isMenuOpened} openMenuCallback={openMenu} navCssClasses="topnav-navbar topnav-navbar-dark" />
			</Suspense>
			<Container fluid>
				<div className="wrapper">
					<Suspense fallback={<LayoutLoading />}>
						<LeftSidebar {...props} isMenuOpened={isMenuOpened} isCondensed={isCondensed} isLight={isLight} hideLogo />
					</Suspense>

					<div className="content-page">
						<div className="content">
							<Suspense fallback={<LayoutLoading />}>{children}</Suspense>
						</div>

						<Suspense fallback={<LayoutLoading />}>
							<Footer />
						</Suspense>
					</div>
				</div>
			</Container>
			<Suspense fallback={<LayoutLoading />}>
				<RightSidebar {...props}>
					<ThemeCustomizer />
				</RightSidebar>
			</Suspense>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};
export default connect(mapStateToProps, { changeLayout })(DetachedLayout);
