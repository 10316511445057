import { toast } from "react-toastify";
import axios from "axios";
import Bugsnag from "@bugsnag/js";
import { API_HOST } from "../config";

export const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: `${API_HOST}/api`,
	headers: {
		"Content-Type": "application/json",
	},
});

const loginPath = "/account/login";
axiosInstance.interceptors.request.use(
	(config) => {
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.has("token")) {
			config.headers.common["token"] = urlParams.get("token");
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		// Check for a 401 response
		if (error?.response?.status === 401 && window.location.pathname !== loginPath) {
			// Redirect to the login page
			// window.location.href = loginPath;
		}
		Bugsnag.notify(error);
		// Return any other error
		return Promise.reject(error);
	},
);

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
export const fetchJSON = (url, options = {}) => {
	return fetch(url, options)
		.then((response) => {
			if (!(response.status === 200)) {
				throw response.json();
			}
			return response.json();
		})
		.then((json) => {
			return json;
		})
		.catch((error) => {
			throw error;
		});
};

export const showSuccessToast = (title) => {
	toast.success(title, {
		toastId: title.replaceAll(" "),
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};

export const showWarningToast = (title) => {
	toast.warning(title, {
		toastId: title.replaceAll(" "),
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
	});
};
