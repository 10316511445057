import Moment from "react-moment";
import React from "react";
import classNames from "classnames";
import moment from "moment";

import { formatMoney, isToday, materialCosts } from "./functions";
import ItemPreview, { getItemTextPreview, getItemTextPreviewCSV } from "../pages/itemBank/createItem/ItemPreview";
import { unitOfMeasurementMetrics } from "../constants";
import { getProjectName } from "./ProjectUtil";
import { getTaxCost } from "./PurchaseOrdertUtil";
import Bugsnag from "@bugsnag/js";

const getInventoryItemUnitPrice = (row) => {
	let unitPrice = 0;
	unitPrice = row.unit_price;
	return unitPrice || 0;
};

export const PriceColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>$0.00</span>;
	const value = getInventoryItemUnitPrice(row);
	return <span>{"$" + formatMoney(value)}</span>;
};

export const PriceColumnUnitPrice = (cell, row, rowIndex, extraData) => {
	if (!cell) return <span>$0.00</span>;
	return <span>{"$" + formatMoney(cell)}</span>;
};

export const TotalCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>$0.00</span>;
	var totalCost = row.quantity.ordered * 1 * (getInventoryItemUnitPrice(row) * 1);

	const value = totalCost * 1 || 0;
	return <span>{"$" + formatMoney(value)}</span>;
};

export const OriginalCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>$0.00</span>;
	let totalCost;
	const unitPrice = getInventoryItemUnitPrice(row);
	if (typeof row.quantity.original_ordered === "undefined") {
		totalCost = row.quantity.ordered * 1 * (unitPrice * 1);
	} else {
		totalCost = row.quantity.original_ordered * 1 * (unitPrice * 1);
	}

	const value = totalCost * 1 || 0;
	return <span>{"$" + formatMoney(value)}</span>;
};

export const CostColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return <span>$0.00</span>;

	const value = cell * 1 || 0;
	return <span>{"$" + formatMoney(value)}</span>;
};

export const CSVTotalCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>$0.00</span>;

	var totalCost = row.quantity.ordered * 1 * (getInventoryItemUnitPrice(row) * 1);

	const value = (totalCost * 1 || 0).toFixed(2);
	return value;
};

export const CSVOriginalCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>$0.00</span>;
	let totalCost;
	const unitPrice = getInventoryItemUnitPrice(row);
	if (typeof row.quantity.original_ordered === "undefined") {
		totalCost = row.quantity.ordered * 1 * (unitPrice * 1);
	} else {
		totalCost = row.quantity.original_ordered * 1 * (unitPrice * 1);
	}

	const value = (totalCost * 1 || 0).toFixed(2);
	return value;
};

export const TotalQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return <span>0.00</span>;

	var totalQuantity = cell * 1;

	const value = totalQuantity * 1 || 0;
	return <span>{formatMoney(value)}</span>;
};

export const uomColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return "";

	return <span>{row?.variant?.uom || ""}</span>;
};

export const umColumnCsv = (cell, row, rowIndex, extraData) => {
	if (!row) return "";

	return row?.variant?.uom || "";
};

export const OriginalQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	let originalQuantity;
	if (typeof row.quantity.original_ordered === "undefined") {
		originalQuantity = row.quantity.ordered * 1;
	} else {
		originalQuantity = row.quantity.original_ordered * 1;
	}

	const value = originalQuantity * 1 || 0;
	return <span>{formatMoney(value)}</span>;
};

export const ItemDescriptionColumn = (cell, row, white, extraData) => {
	if (row?.associated_item_bank) {
		let data = row.associated_item_bank;

		if (row.variant) {
			const {
				price,
				size,
				vendor,
				manufacturer_code,
				vendor_code,
				sub_description,
				finish,
				color,
				description,
				custom_field,
				createdBy,
				modifiedBy,
				createdAt,
				updatedAt,
			} = row.variant;
			data = {
				manufacturer: row.associated_item_bank.manufacturer,
				manufacturer_codes: manufacturer_code ? [manufacturer_code] : [],
				vendors: vendor ? [vendor] : [],
				vendor_codes: vendor_code ? [vendor_code] : [],
				descriptions: row.associated_item_bank.descriptions,
				sub_descriptions: sub_description ? [sub_description] : [],
				finishes: finish ? [finish] : [],
				colors: color ? [color] : [],
				sizes: size ? [size] : [],
				prices: price ? [price] : [],
				description: description ? description : [],
				custom_field: custom_field ? custom_field : null,
				createdBy: createdBy,
				modifiedBy: modifiedBy,
				createdAt: createdAt,
				updatedAt: updatedAt,
			};
		}
		return <ItemPreview data={data} showFieldLabel hiddenFields={["vendors", "manufacturer"]} maxWidth={"max-content"} simple handleOnClick={() => {}} />;
	}

	return <span>{row.item_code}</span>;
};

export const formatSizeMeasures = (size) => {
	if (!size) {
		return size;
	}
	if (size.height && size.height.uom === "IN") {
		size.height.uom = '"';
	}

	if (size.length && size.length.uom === "IN") {
		size.length.uom = '"';
	}

	if (size.width && size.width.uom === "IN") {
		size.width.uom = '"';
	}

	return size;
};

export const ItemDescriptionSearchText = (row) => {
	if (row?.associated_item_bank) {
		let data = row.associated_item_bank;

		if (row.variant) {
			const { price, size, vendor, manufacturer_code, vendor_code, sub_description, finish, color } = row.variant;
			data = {
				manufacturer: row.associated_item_bank.manufacturer,
				manufacturer_codes: row.associated_item_bank?.manufacturer_codes?.filter(
					(manufacturerCode) => manufacturerCode && manufacturerCode.code === manufacturer_code,
				),
				vendors: vendor ? [vendor] : [],
				vendor_codes: row.associated_item_bank?.vendor_codes?.filter(({ code }) => code === vendor_code),
				descriptions: row.associated_item_bank?.descriptions,
				sub_descriptions: sub_description ? [sub_description] : [],
				finishes: finish ? [finish] : [],
				colors: color ? [color] : [],
				sizes: size ? [size] : [],
				prices: price ? [price] : [],
				quantitycurrent: row.quantity.current,
				quantityout: row.quantity.out,
				quantityarrived: row.quantity.arrived,
				originalmaterial: CSVOriginalQuantityColumn(null, row),
				totalcost: CSVTotalCostColumn(null, row),
			};
		}
		return JSON.stringify(data);
	}
	return row.item_code;
};

export const ItemBankRowSearch = (row, searchText) => {
	row = row.item;
	let data = row;
	let match = [];

	if (row) {
		const { sizes, vendors, vendor_codes, sub_descriptions, finish, colors } = row;

		data = {
			manufacturer: row.manufacturer,
			manufacturer_codes: row.manufacturer_codes,
			vendors: vendors ? [vendors] : [],
			vendor_codes: vendor_codes ? [vendor_codes] : [],
			descriptions: row.descriptions,
			sub_descriptions: sub_descriptions ? [sub_descriptions] : [],
			finishes: finish ? [finish] : [],
			colors: colors ? [colors] : [],
			sizes: sizes ? [sizes] : [],
		};

		for (const key in data) {
			try {
				if (Object.hasOwnProperty.call(data, key)) {
					const element = data[key];
					const elementString = JSON.stringify(element);

					if (elementString.toLowerCase().includes(searchText.toLowerCase())) {
						match.push(key);
					}
				}
			} catch (e) {
				Bugsnag.notify(e);
			}
		}

		return match;
	}
	return false;
};

export const ManufacturerColumn = (cell, row, rowIndex, extraData) => {
	if (row?.associated_item_bank) {
		return <span>{row.associated_item_bank.manufacturer?.name}</span>;
	}
	return <span>{row.manufacturer}</span>;
};

export const ProjectNameColumn = (cell, row, rowIndex, extraData) => {
	if (row.associated_job) {
		return <span>{getProjectName(row?.associated_job)}</span>;
	}
	if (row.project) {
		return <span>{getProjectName(row?.project)}</span>;
	}
	return <span>{getProjectName(row)}</span>;
};

export const CSVProjectNameColumn = (cell, row, rowIndex, extraData) => {
	if (row.associated_job) {
		return getProjectName(row.associated_job);
	}
	if (row.project) {
		return getProjectName(row.project);
	}
	return getProjectName(row);
};

export const VendorColumn = (cell, row, rowIndex, extraData) => {
	if (row.variant && row.variant.vendor) {
		return <span>{row.variant.vendor.name}</span>;
	} else if (row.associated_item_bank && row.associated_item_bank.manufacturer) {
		return <span>{row.associated_item_bank.manufacturer.name}</span>;
	} else if (row.associated_po && row.associated_po?.vendor) {
		return <span>{row.associated_po?.vendor}</span>;
	}
	return <span>{row.manufacturer}</span>;
};

export const CSVVendorColumn = (cell, row, rowIndex, extraData) => {
	if (row.variant && row.variant.vendor) {
		return row.variant.vendor.name || "";
	} else if (row.associated_item_bank && row.associated_item_bank.manufacturer) {
		return row.associated_item_bank.manufacturer.name || "";
	} else if (row.associated_po && row.associated_po?.vendor) {
		return row.associated_po?.vendor;
	}
	return row.manufacturer ? row.manufacturer : "";
};

export const ProjectVendorColumn = (cell, row, rowIndex, extraData) => {
	const inventory_items_vendors = row.inventory_items.map((item) => item?.variant?.name).filter((value) => value != null);
	const purchase_orders_vendors = row.purchase_orders.map(({ vendor }) => vendor).filter((value) => value != null);
	const vendors = new Set(inventory_items_vendors.concat(purchase_orders_vendors));

	return <span>{vendors.size === 1 ? [...vendors][0] : vendors.size > 1 ? "Multiple" : "-"}</span>;
};

export const CSVProjectVendorColumn = (cell, row, rowIndex, extraData) => {
	const inventory_items_vendors = row.inventory_items.map((item) => item?.variant?.name).filter((value) => value != null);
	const purchase_orders_vendors = row.purchase_orders.map(({ vendor }) => vendor).filter((value) => value != null);
	const vendors = new Set(inventory_items_vendors.concat(purchase_orders_vendors));

	return vendors.size === 1 ? [...vendors][0] : vendors.size > 1 ? "Multiple" : "-";
};

export const OutstandingQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const outstanding = row.quantity.ordered - row.quantity.arrived;

	const value = outstanding * 1 || 0;
	return <span>{formatMoney(value)}</span>;
};

export const OutstandingAdjustmentQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const outstanding = row.new_inventory_levels.ordered - row.new_inventory_levels.arrived;

	const value = outstanding * 1 || 0;
	return <span>{formatMoney(value)}</span>;
};

export const CSVTotalQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return 0;

	var totalQuantity = cell * 1;

	const value = totalQuantity * 1 || 0;
	return formatMoney(value);
};

export const CSVOutstandingQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return 0;

	const outstanding = row.quantity.ordered - row.quantity.arrived;

	const value = outstanding * 1 || 0;
	return formatMoney(value);
};

export const CSVOutstandingAdjustmentQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return 0;

	const outstanding = row.new_inventory_levels.ordered - row.new_inventory_levels.arrived;

	const value = outstanding * 1 || 0;
	return formatMoney(value);
};

export const CSVOriginalQuantityColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return 0;

	let originalQuantity;
	if (typeof row.quantity.original_ordered === "undefined") {
		originalQuantity = row.quantity.ordered * 1;
	} else {
		originalQuantity = row.quantity.original_ordered * 1;
	}

	const value = originalQuantity * 1 || 0;
	return formatMoney(value);
};

export const MaterialCostsColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const { inventory_items } = row;

	var MaterialCosts = materialCosts(inventory_items);

	const value = formatMoney(MaterialCosts * 1 || 0);
	return <span>${value}</span>;
};

export const CSVMaterialCostsColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const { inventory_items } = row;

	var MaterialCosts = materialCosts(inventory_items);

	const value = formatMoney(MaterialCosts * 1 || 0);
	return value;
};

export const TotalCostsColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const { inventory_items, otherCost, deliveryCost } = row;

	return <span>${formatMoney(materialCosts(inventory_items) + otherCost + deliveryCost + getTaxCost(row) || 0)}</span>;
};

export const taxCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	return <span>${formatMoney(getTaxCost(row) || 0)}</span>;
};

export const rowIndexColumn = (cell, row, rowIndex, extraData) => {
	return <span>{rowIndex + 1}</span>;
};

export const CSVTotalCostsColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return <span>0.00</span>;

	const { inventory_items } = row;

	var MaterialCosts = materialCosts(inventory_items);

	const { otherCost, deliveryCost, taxCost } = row;
	const TotalCosts = MaterialCosts + otherCost + deliveryCost + taxCost;

	const value = formatMoney(TotalCosts * 1 || 0);
	return value;
};

export const ArrayCountColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return 0;
	return cell.length || 0;
};

export const CSVArrayCountColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return 0;
	return cell.length || 0;
};

export const DateColumn = (cell, row, rowIndex, extraData) => {
	return (
		<React.Fragment>
			<h5>
				<span
					className={classNames("badge", {
						"badge-success-lighten": isToday(cell),
					})}>
					<Moment format="MM/DD/YY">{cell}</Moment>
				</span>
			</h5>
		</React.Fragment>
	);
};

export const CSVDateColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return "";
	return moment(cell).format("MM/DD/YYYY");
};

export const DifferenceCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return 0;

	const unit_price = getInventoryItemUnitPrice(row.associated_inventory_item);
	const { previous_inventory_levels, new_inventory_levels } = row;

	const value = formatMoney((new_inventory_levels[extraData.type] - previous_inventory_levels[[extraData.type]]) * unit_price * 1);

	return value;
};

export const DifferenceOutstandingCostColumn = (cell, row, rowIndex, extraData) => {
	if (!row) return 0;

	const unit_price = getInventoryItemUnitPrice(row.associated_inventory_item);
	const { previous_inventory_levels, new_inventory_levels } = row;

	const value = formatMoney(
		(new_inventory_levels[extraData.ordered] -
			new_inventory_levels[extraData.arrived] -
			(previous_inventory_levels[[extraData.ordered]] - previous_inventory_levels[[extraData.arrived]])) *
			unit_price *
			1,
	);

	return value;
};

export const ShouldArriveDateColumn = (cell, row, rowIndex, extraData) => {
	if (!cell) return "";

	const isLate = row.quantity.arrived < 1 && new Date(row.estimated_delivery_date) <= new Date();

	return (
		<React.Fragment>
			<h5>
				<span
					className={classNames("badge", {
						"badge-danger-lighten": isLate,
					})}>
					<Moment format="MM/DD/YY">{cell}</Moment>
				</span>
			</h5>
		</React.Fragment>
	);
};

export const ItemSizeColumn = (cell, row, rowIndex, extraData) => {
	const cellText = formatItemSizeText(cell);

	return (
		<React.Fragment>
			<span>{cellText}</span>
		</React.Fragment>
	);
};

export const formatPhoneNumber = (phoneNumber, onlyDigits = false) => {
	if (phoneNumber) {
		let digits = phoneNumber.includes("+") ? phoneNumber.slice(2).replace(/\D/g, "") : phoneNumber.replace(/\D/g, "");
		if (onlyDigits) return digits;
		else if (digits.length === 10) {
			let formattedNumber = `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
			return formattedNumber;
		} else {
			return phoneNumber;
		}
	} else {
		return null;
	}
};

export const CsvItemSizeColumn = (cell, row, rowIndex, extraData) => {
	const cellText = formatItemSizeText(cell);

	return cellText;
};

export function formatItemSizeText(cell) {
	if (!((typeof cell === "object" || typeof cell === "function") && cell !== null)) {
		return "";
	}

	cell = formatSizeMeasures(cell);
	if (!cell) {
		return "";
	}

	if (!cell.height && !cell.width && !cell.length) {
		return "";
	}
	const tempArr = [];

	if (cell.length && (cell.length.value || cell.length.fraction)) {
		tempArr.push({
			value: cell.length.value,
			fraction: cell.length.fraction,
			uom: cell.length.uom,
		});
	}

	if (cell.width && (cell.width.value || cell.width.fraction)) {
		tempArr.push({
			value: cell.width.value,
			fraction: cell.width.fraction,
			uom: cell.width.uom,
		});
	}

	tempArr.sort((a, b) => a.value - b.value);
	tempArr.sort((a, b) => {
		if (a.uom === '"' && b.uom !== '"') {
			return -1;
		} else if (a.uom !== '"' && b.uom === '"') {
			return 1;
		}
		return 0;
	});

	if (cell.height && (cell.height.value || cell.height.fraction)) {
		tempArr.push({
			value: cell.height.value,
			fraction: cell.height.fraction,
			uom: cell.height.uom,
		});
	}

	function getItem(size, idx) {
		let result = "";
		if (size.value !== "" && size.value != null) {
			result += size.value;
		}
		if (size.fraction !== "" && size.fraction != null) {
			size.fraction = size.fraction.replace(".", "");
			if (unitOfMeasurementMetrics.IN.shortName === size.uom && unitOfMeasurementMetrics.IN.fractions.indexOf(size.fraction) >= 0) {
				result += `${result.length !== 0 ? " " : ""}${size.fraction} `;
			} else {
				result += `${result.length !== 0 ? "." + size.fraction : size.fraction} `;
			}
		}
		if (size.uom !== "" && size.uom != null) {
			result += ` ${size.uom}`;
		}
		return result;
	}

	return tempArr
		.map((size, idx) => {
			return getItem(size, idx);
		})
		.join(" x ");
}

export const poDescriptionColumn = (cell, row, rowIndex, extraData) => {
	return ItemDescriptionColumn(null, row, true);
};

export const poDescriptionColumnExport = (cell, row, rowIndex, extraData) => {
	return getItemTextPreviewCSV(row, false);
};
