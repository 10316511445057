import { axiosInstance, showSuccessToast, showWarningToast } from "../helpers";

const baseRoute = "/inventoryadjustments";

export const getAllInventoryAdjustments = async () => {
	const res = await axiosInstance.get(baseRoute);
	return res.data.inventoryAdjustments;
};

export const updateOneInventoryAdjustment = async (updatedValues: Object, adjustmentId: string) => {
	const res = await axiosInstance.put(`${baseRoute}/${adjustmentId}`, updatedValues);
	return res.data.associatedInventoryItem;
};

export const getAdjustmentsByDate = async (startDate, endDate) => {
	const params = new URLSearchParams();
	params.append("start", startDate);
	params.append("end", endDate);
	const response = await axiosInstance.get(`${baseRoute}?${params.toString()}`);
	return response.data;
};

export const createNewItemAdjustment = async (newItemAdjustmentValues) => {
	const response = await axiosInstance.post(baseRoute, { newInventoryAdjustment: newItemAdjustmentValues });
	return response.data?.createdInventoryAdjustment;
};

export const createNewItemAdjustmentList = async ({ newInventoryAdjustmentList }) => {
	const response = await axiosInstance.post(baseRoute, { newInventoryAdjustmentList });
	return response.data.createdInventoryAdjustmentList;
};

export const apiAssignAdjustmentsToPackingList = async (id, assignedIds, purchaseOrderId) => {
	try {
		const res = await axiosInstance.put("/packing_list", {
			id: id,
			purchaseOrderId: purchaseOrderId,
			assignedAdjustmentIds: assignedIds,
		});

		if (res.status < 300 && res.status >= 200) {
			showSuccessToast("Successful Packing List Assign To Inventory Adjustment");
			return res.data || [];
		}
	} catch (err) {}
	showWarningToast("Failed Packing List Assign To Inventory Adjustment");
	return [];
};

export const apiGetAdjustmentByIds = async (ids) => {
	if (ids.length === 0) {
		return { data: [] };
	}
	try {
		const res = await axiosInstance.post("/inventoryAdjustmentsByIdList", {
			inventoryAdjustmentIds: ids,
		});

		if (res.status < 300 && res.status >= 200) {
			return res.data || { data: [] };
		}
	} catch (err) {
		return { data: [] };
	}
};

export const apiRemovePackingListFromAdjustment = async (adjustmentId, fileId) => {
	try {
		const res = await axiosInstance.delete(`/inventoryAdjustments/packing_list/${adjustmentId}/${fileId}`);
		if (res.status < 300 && res.status >= 200) {
			showSuccessToast("Successful Packing List Remove From Adjustment");
			return res.data || [];
		}
	} catch (err) {}
	showWarningToast("Failed Packing List Remove From Adjustment");
	return [];
};
