export const getProjectName = (project, onlySubName = false) => {
	let result;

	if (onlySubName) {
		result = project?.subProjectName;
	} else {
		result = project?.name;

		if (project?.subProjectName) {
			result = `${result} - ${project.subProjectName}`;
		}
	}

	return result || "-";
};
