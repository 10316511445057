import { ADJUSTMENT_OPTIONS, USER_ROLE } from "../constants";

export const isAdjustmentEnabled = (adjustmentType, role) => {
	if (role === USER_ROLE.SUPERINTENDENT.textkey || role === USER_ROLE.WAREHOUSE_ATTENDANT.textkey) {
		return !(adjustmentType === ADJUSTMENT_OPTIONS.ADDED_TO_STORAGE || adjustmentType === ADJUSTMENT_OPTIONS.INVENTORY_REMOVED_FROM_WAREHOUSE);
	}
	return true;
};

export const getAdjustmentLabel = (option) => {
	const labelMap = {
		INVENTORY_ARRIVED_TO_WAREHOUSE: "Received at Warehouse",
		RECEIVED_AT_JOBSITE: "Received at Jobsite",
		INVENTORY_DIRECTLY_TO_JOBSITE: "Directly to Install",
		RETURN_FROM_JOBSITE: "Return from Jobsite ",
		ADDED_TO_STORAGE: "Added to Storage",
		INVENTORY_REMOVED_FROM_WAREHOUSE: "Removed from Storage",
		INVENTORY_FROM_WAREHOUSE_TO_JOBSITE: "From Storage to Install",
		INVENTORY_REQUEST_TO_JOBSITE: "Request to Jobsite",
		INVENTORY_MOVED_TO_ANOTHER_PROJECT: (value) => `Moved to ${value} (Storage)`,
		TRANSFER_TO_ANOTHER_PROJECT_INSTALL: (value) => `Moved to ${value} (Install)`,
		INVENTORY_MOVED_FROM: (value) => `Moved from ${value} (Storage)`,
		INVENTORY_MOVED_FROM_INSTALL: (value) => `Moved from${value} (Install)`,
		REVERSAL: "Reversal",
		INVENTORY_ARRIVED_TO_WAREHOUSE_REVERSAL: "Received at Warehouse Reversal",
		INVENTORY_FROM_WAREHOUSE_TO_JOBSITE_REVERSAL: "From Storage to Install Reversal",
		INVENTORY_DIRECTLY_TO_JOBSITE_REVERSAL: "Directly to Install Reversal",
		INVENTORY_REMOVED_FROM_WAREHOUSE_REVERSAL: "Removed from Storage Reversal",
	};

	let result = option || "";
	let optionKey = Object.keys(ADJUSTMENT_OPTIONS).filter((key) => option === ADJUSTMENT_OPTIONS[key]);
	if (optionKey.length > 0 && labelMap[optionKey[0]] != null) {
		result = labelMap[optionKey[0]];
	}
	if ((option || "").includes(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_FROM_INSTALL)) {
		return labelMap.INVENTORY_MOVED_FROM_INSTALL(option.replace(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_FROM_INSTALL, ""));
	} else if ((option || "").includes(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_FROM)) {
		return labelMap.INVENTORY_MOVED_FROM(option.replace(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_FROM, ""));
	} else if ((option || "").includes(ADJUSTMENT_OPTIONS.TRANSFER_TO_ANOTHER_PROJECT_INSTALL)) {
		return labelMap.TRANSFER_TO_ANOTHER_PROJECT_INSTALL(option.replace(ADJUSTMENT_OPTIONS.TRANSFER_TO_ANOTHER_PROJECT_INSTALL, ""));
	} else if ((option || "").includes(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_TO_ANOTHER_PROJECT)) {
		return labelMap.INVENTORY_MOVED_TO_ANOTHER_PROJECT(option.replace(ADJUSTMENT_OPTIONS.INVENTORY_MOVED_TO_ANOTHER_PROJECT, ""));
	}
	return result.replace(/^INVENTORY /g, "");
};

export function isEmptyString(str) {
	return !str || str.length === 0;
}

export function isEmptyObject(obj) {
	return JSON.stringify(obj) === "{}";
}

export const getPageTitle = (label, { selectedLocation, selectedState }) => {
	console.log("label ", label);
	console.log("selectedLocation ", selectedLocation);
	console.log("selectedState ", selectedState);
	let pageTitle = "";

	if ((selectedState && selectedState) || (selectedLocation && selectedLocation)) {
		if (selectedState && selectedState) {
			pageTitle += `${selectedState.name} `;
		}
		if (selectedLocation && selectedLocation) {
			pageTitle += `${selectedLocation.map((location) => location.name)} `;
		}
	} else {
		pageTitle += "All ";
	}
	pageTitle += label;
	return pageTitle;
};

export const removeDuplicates = (fullArray) => {
	const valueArr = fullArray.map((item) => {
		return typeof item == "object" ? JSON.stringify(item) : item;
	});
	return fullArray.filter((item, idx) => {
		return valueArr.indexOf(typeof item == "object" ? JSON.stringify(item) : item) === idx;
	});
};

export const removeDuplicatesById = (fullArray) => {
	const valueArr = fullArray.map(function (item) {
		return item._id;
	});
	return fullArray.filter(function (item, idx) {
		return valueArr.indexOf(item._id) === idx;
	});
};

export const getSizeOptions = (manufacturerName, itemBanks) => {
	let sizeOptions = [];
	itemBanks.forEach((item) => {
		if (item.manufacturer?.name === manufacturerName) {
			sizeOptions = sizeOptions.concat(item.sizes);
		}
	});

	return removeDuplicates(
		sizeOptions.sort(function (a, b) {
			if (a.width.value > b.width.value) {
				return 1;
			} else if (a.width.value < b.width.value) {
				return -1;
			}
			return 0;
		}),
	);
};

export const removeOnlyCustomItemField = (fullArray) => {
	let result = [];

	fullArray.forEach((item) => {
		const keys = Object.keys(item);

		if (keys && !(keys.length === 0 || (keys.length === 1 && keys[0] === "custom_item"))) {
			result = result.concat(item);
		}
	});

	return result;
};

export function deepObjectsEqual(obj1, obj2) {
	if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
		return obj1 === obj2;
	}

	const keys1 = Object.keys(obj1);
	const keys2 = Object.keys(obj2);

	if (keys1.length !== keys2.length) return false;

	if (!keys1.every((key) => keys2.includes(key))) return false;

	for (const key of keys1) {
		const val1 = obj1[key];
		const val2 = obj2[key];

		if (!deepObjectsEqual(val1, val2)) {
			return false;
		}
	}

	return true;
}

export const variantContainsValue = (valuesA, variant) => {
	let globalMatch = false;
	for (let index = 0; index < valuesA.length; index++) {
		const values = valuesA[index];

		let notMatch = false;
		for (const key in values) {
			if (values[key] && variant[key]) {
				let value = values[key];
				if (key === "vendor") {
					if (variant.vendor.name !== value.name) {
						notMatch = true;
					}
				} else if (typeof value === "object") {
					if (value._id) delete value._id;
					if (variant[key]._id) delete variant[key]._id;
					const { custom_item, ...others } = value;
					value = others;

					const { custom_item: custom, ...variantKeyValues } = variant[key];
					const keyValues = variantKeyValues;
					if (!deepObjectsEqual(keyValues, value)) {
						notMatch = true;
					}
				} else {
					if (variant[key] === value) {
					} else {
						notMatch = true;
					}
				}
			} else if (values[key]) {
				notMatch = true;
			}
		}
		if (!globalMatch) globalMatch = !notMatch;
	}
	if (globalMatch) {
		return true;
	} else {
		return false;
	}
};

export const sizeComparison = (sizeA, sizeB) => {
	if ((sizeA && !sizeB) || (!sizeA && sizeB)) {
		return false;
	}

	if ((sizeA.width && !sizeB.width) || (!sizeA.width && sizeB.width)) {
		return false;
	}

	if ((sizeA.height && !sizeB.height) || (!sizeA.height && sizeB.height)) {
		return false;
	}

	if ((sizeA.length && !sizeB.length) || (!sizeA.length && sizeB.length)) {
		return false;
	}

	if (sizeA.width && sizeB.width) {
		if ((sizeA.width.value && !sizeB.width.value) || (!sizeA.width.value && sizeB.width.value)) {
			return false;
		}

		if ((sizeA.width.fraction && !sizeB.width.fraction) || (!sizeA.width.fraction && sizeB.width.fraction)) {
			return false;
		}

		if ((sizeA.width.uom && !sizeB.width.uom) || (!sizeA.width.uom && sizeB.width.uom)) {
			return false;
		}

		if (sizeA.width.value && sizeB.width.value && sizeA.width.value !== sizeB.width.value) {
			return false;
		}

		if (sizeA.width.fraction && sizeB.width.fraction && sizeA.width.fraction !== sizeB.width.fraction) {
			return false;
		}

		if (sizeA.width.uom && sizeB.width.uom && sizeA.width.uom !== sizeB.width.uom) {
			return false;
		}
	}

	if (sizeA.height && sizeB.height) {
		if ((sizeA.height.value && !sizeB.height.value) || (!sizeA.height.value && sizeB.height.value)) {
			return false;
		}

		if ((sizeA.height.fraction && !sizeB.height.fraction) || (!sizeA.height.fraction && sizeB.height.fraction)) {
			return false;
		}

		if ((sizeA.height.uom && !sizeB.height.uom) || (!sizeA.height.uom && sizeB.height.uom)) {
			return false;
		}

		if (sizeA.height.value && sizeB.height.value && sizeA.height.value !== sizeB.height.value) {
			return false;
		}

		if (sizeA.height.fraction && sizeB.height.fraction && sizeA.height.fraction !== sizeB.height.fraction) {
			return false;
		}

		if (sizeA.height.uom && sizeB.height.uom && sizeA.height.uom !== sizeB.height.uom) {
			return false;
		}
	}

	if (sizeA.length && sizeB.length) {
		if ((sizeA.length.value && !sizeB.length.value) || (!sizeA.length.value && sizeB.length.value)) {
			return false;
		}

		if ((sizeA.length.fraction && !sizeB.length.fraction) || (!sizeA.length.fraction && sizeB.length.fraction)) {
			return false;
		}

		if ((sizeA.length.uom && !sizeB.length.uom) || (!sizeA.length.uom && sizeB.length.uom)) {
			return false;
		}

		if (sizeA.length.value && sizeB.length.value && sizeA.length.value !== sizeB.length.value) {
			return false;
		}

		if (sizeA.length.fraction && sizeB.length.fraction && sizeA.length.fraction !== sizeB.length.fraction) {
			return false;
		}

		if (sizeA.length.uom && sizeB.length.uom && sizeA.length.uom !== sizeB.length.uom) {
			return false;
		}
	}

	return true;
};

export const getDifference = (array1, array2) => {
	const diffBetween = [];
	const isObject = (v) => v && typeof v === "object";
	function getDifferenceRecursive(arrayA, arrayB, mk) {
		for (const key in arrayA) {
			if (isObject(arrayA[key]) && isObject(arrayB[key]) && Object.keys(arrayA[key]).length > 0 && Object.keys(arrayB[key]).length > 0) {
				getDifferenceRecursive(arrayA[key], arrayB[key], key);
			} else {
				if (arrayA[key] !== arrayB[key]) {
					if (!diffBetween.includes(mk ? mk : key)) {
						diffBetween.push(mk ? mk : key);
					}
				}
			}
		}
	}

	getDifferenceRecursive(array1, array2);
	if (diffBetween.some((elem) => ["length", "width", "height"].includes(elem))) {
		diffBetween.push("size");
	}
	return diffBetween;
};

export const isEmptyOrSpaces = (str) => {
	return !str || str.replace(/\s/g, "").length < 1;
};
