import React, { useCallback, useContext, useEffect, useState } from "react";
import { Avatar, AvatarGroup, ChatContainer, ConversationHeader, Message, MessageGroup, MessageInput, MessageList } from "@chatscope/chat-ui-kit-react";
import UserAvatarDefault from "./UserAvatarDefault";
import { Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiDelete, mdiEye, mdiLink } from "@mdi/js";
import { axiosInstance, getParticipantsFullNames, showSuccessToast, showWarningToast } from "../../../helpers";
import Spinner from "../../../components/Spinner";
import { useQueryClient } from "@tanstack/react-query";
import DeleteForm from "../../../components/custom/DeleteForm";
import FormModal from "../../../components/custom/FormModal";
import { ConversationContext } from "../../../context/ConversationContextProvider";
import classNames from "classnames";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";

const Chat = ({ onTargetLinkClick = undefined, conversation, showTargetLink = true, collapsed, back = () => {}, onDeleteConversation = null }) => {
	const [messages, setMessages] = useState([]);
	const [currentMessage, setCurrentMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const queryClient = useQueryClient();
	const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
	const history = useHistory();
	const { isModalOpen, setIsModalOpen, conversationId, setConversationId } = useContext(ConversationContext);
	const { user: currentUser } = useAuthContext();

	useEffect(() => {
		(async () => {
			setIsLoading(true);
			const conversationId = conversation?._id;
			try {
				const msgData = await axiosInstance.get(`/conversations/${conversationId}/messages`);
				await axiosInstance.put(`/conversations/${conversationId}/seen`);
				queryClient.invalidateQueries({ queryKey: ["conversations"] });
				queryClient.invalidateQueries({ queryKey: ["unseenConversations"] });
				setMessages(msgData.data);
			} catch (err) {
				Bugsnag.notify(err);
			}
			setIsLoading(false);
		})();
	}, [conversation]);

	const onMsgSend = async () => {
		try {
			const msg = await axiosInstance.post(`/conversations/${conversation._id}/messages`, { content: currentMessage, type: "TEXT" });
			setMessages((prevState) => [...prevState, msg.data]);
			setCurrentMessage("");
		} catch (err) {
			Bugsnag.notify(err);
		}
	};

	const onMsgInputChange = (value) => setCurrentMessage(value);

	const isCurrentUser = useCallback(({ email }) => email === currentUser.email, [currentUser]);

	const getParticipantsChatHeader = useCallback(() => {
		const names = getParticipantsFullNames(conversation?.participants || [], currentUser.email);
		return <span title={names}>{names}</span>;
	}, [currentUser, conversation]);

	const onDeleteChat = useCallback(async () => {
		try {
			await axiosInstance.delete(`/conversations/${!!conversation ? conversation._id : conversationId}`);
			queryClient.setQueryData(["conversations"], (old) => old.filter((item) => item._id !== (conversation?._id || conversationId)));
			if (isModalOpen) {
				setConversationId(null);
				setIsModalOpen(false);
			} else {
				if (onDeleteConversation) onDeleteConversation(conversation._id);
				if (!conversationId) history.replace("/conversations");
			}
			setDisplayDeleteModal(false);
			showSuccessToast("Conversation deleted!");
		} catch (error) {
			showWarningToast("Something gets wrong. Try later!");
		}
	}, [conversation, conversationId]);

	return (
		<>
			{!isLoading ? (
				<>
					<ChatContainer>
						<ConversationHeader>
							<ConversationHeader.Back onClick={back} />
							<AvatarGroup size="md">
								{conversation?.participants?.map(({ avatar, firstname, _id }, index, array) => (
									<Avatar
										className="border-0"
										key={index}
										src={avatar?.image}
										name={firstname}
										children={!avatar.image ? <UserAvatarDefault {...array[index]} /> : undefined}
									/>
								))}
							</AvatarGroup>
							<ConversationHeader.Content className="font-weight-bold d-flex justify-content-start flex-row align-items-center pr-2">
								{getParticipantsChatHeader()}
								<Icon
									title="Delete Conversation"
									className={classNames("cursor-pointer ml-1", { "mr-1 ml-auto": showTargetLink })}
									path={mdiDelete}
									size={1}
									onClick={() => setDisplayDeleteModal(true)}
									style={{ minWidth: "25px" }}
								/>
								{showTargetLink && (
									<Icon
										title="Target link"
										className="cursor-pointer"
										path={mdiLink}
										size={1}
										onClick={(e) => {
											if (onTargetLinkClick) onTargetLinkClick(e, conversation);
										}}
										style={{ minWidth: "25px" }}
									/>
								)}
							</ConversationHeader.Content>
						</ConversationHeader>
						<MessageList hidden={collapsed}>
							{messages.map(({ createdBy, content, seenBy, ...msg }, index, array) => (
								<MessageGroup
									key={index}
									direction={isCurrentUser(createdBy) ? "outgoing" : "incoming"}
									avatarPosition={isCurrentUser(createdBy) ? "br" : "tl"}>
									<Avatar
										src={createdBy.avatar?.image}
										name={createdBy.firstname}
										children={!createdBy.avatar.image ? <UserAvatarDefault {...createdBy} /> : undefined}
									/>
									<MessageGroup.Messages>
										<Message model={{ payload: content }}>
											<Message.Footer
												className={`justify-content-end ${isCurrentUser(createdBy) ? "flex-row-reverse" : ""}`}
												children={
													<>
														{getParticipantsFullNames(seenBy, currentUser.email, false, null, createdBy.email) && (
															<Icon
																path={mdiEye}
																size={1}
																title={getParticipantsFullNames(seenBy, currentUser.email, false, null, createdBy.email)}
																className="mx-2"
															/>
														)}
														{moment(msg.createdAt).format("hh:mm")}
													</>
												}
											/>
										</Message>
									</MessageGroup.Messages>
								</MessageGroup>
							))}
						</MessageList>
						<MessageInput
							hidden={collapsed}
							placeholder="Type message here"
							onSend={onMsgSend}
							value={currentMessage}
							onChange={onMsgInputChange}
							attachButton={false}
						/>
					</ChatContainer>
					<FormModal modalTitle={"Are You Sure?"} toggle={() => setDisplayDeleteModal(false)} displayModal={displayDeleteModal}>
						<DeleteForm warningText="This cannot be undone." handleSubmit={() => onDeleteChat()} />
					</FormModal>
				</>
			) : (
				<Row className="w-75 d-flex justify-content-center pt-3 mx-auto">
					<Spinner size={"md"} type="grow" />
				</Row>
			)}
		</>
	);
};

export default Chat;
