// @flow
import React, { FC, PropsWithChildren, Suspense, useEffect } from "react";
import LayoutLoading from "./LayoutLoading";

const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
	useEffect(() => {
		if (document.body) document.body.classList.add("authentication-bg");
		return () => {
			if (document.body) document.body.classList.remove("authentication-bg");
		};
	}, []);

	return <Suspense fallback={<LayoutLoading />}>{children}</Suspense>;
};

export default AuthLayout;
