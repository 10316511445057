export const itemBankColors = {
	vendorCode: "green",
	material: "cyan",
	manufacturerCode: "chocolate",
	description: "red",
	color: "cadetblue",
	subDescription: "blue",
	size: "blueviolet",
	uom: "darkgray",
	finish: "brown",
	price: "black",
	customFields: "olive",
	customField: "olive",
};

export const FIELD_TYPES = {
	vendor: "vendor",
	vendor_code: "vendor_code",
	manufacturer: "manufacturer",
	manufacturer_code: "manufacturer_code",
	descriptions: {
		value: "descriptions_value",
	},
	finishes: "finishes",
	colors: {
		name: "color_name",
		code: "color_code",
	},
	subDescription: {
		description: "subDescription_description",
		code: "subDescription_code",
	},
	size: {
		width: {
			value: "width_value",
			fraction: "width_fraction",
			uom: "width_uom",
		},
		length: {
			value: "length_value",
			fraction: "length_fraction",
			uom: "length_uom",
		},
		height: {
			value: "height_value",
			fraction: "height_fraction",
			uom: "height_uom",
		},
	},
	unitOfMeasurement: "unit_of_measurement",
	material: "material",
	customField: {
		value: "customField_value",
		name: "customField_name",
	},
	price: "price",
};

export const DEFAULT_METRICS = "IN";
export const DEFAULT_UOM = "SF";

export const ItemBankTabs = {
	ALL: 0,
	VENDOR: 1,
	MANUFACTURER: 2,
	MATERIAL: 3,
};
