import React, { FC, PropsWithChildren, Suspense, useEffect } from "react";
import { Col, Row } from "reactstrap";
import Spinner from "../components/Spinner";
import LayoutLoading from "./LayoutLoading";

const ShareLayout: FC<PropsWithChildren> = ({ children }) => {
	useEffect(() => {
		if (document.body) document.body.classList.add("authentication-bg");

		return () => {
			if (document.body) document.body.classList.remove("authentication-bg");
		};
	}, []);

	return (
		<Row className="py-3 px-2 d-flex justify-content-center">
			<Col xs="auto" md={10} xl={8}>
				<Suspense fallback={<LayoutLoading />}>{children}</Suspense>
			</Col>
		</Row>
	);
};

export default ShareLayout;
