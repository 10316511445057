import React, { useRef, useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = ({ tooltipText, placement = "top", itemText, id }) => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useRef(window.innerWidth < 767);
	const toggle = () => {
		if (isMobile.current) setIsOpen((prevState) => !prevState);
	};

	return (
		<div className={"h-100 d-flex align-items-center position-relative"}>
			{tooltipText ? (
				<div className="d-flex align-items-center">
					{itemText}
					<i
						onClick={isMobile.current ? toggle : undefined}
						onMouseOver={() => setIsOpen(true)}
						onMouseOut={() => setIsOpen(false)}
						className={"mdi mdi-information-outline px-1 pb-1"}
						id={"Tooltip-" + id}></i>
				</div>
			) : (
				<p>{itemText}</p>
			)}
			{tooltipText && (
				<Tooltip placement={placement} isOpen={isOpen} toggle={toggle} target={"Tooltip-" + id}>
					{tooltipText}
				</Tooltip>
			)}
		</div>
	);
};

export default TooltipItem;
