// @flow
import React, { Component, FC, PropsWithChildren, Suspense, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { changeLayout, LayoutAction } from "../redux/actions";
import * as layoutConstants from "../constants/layout";
import ThemeCustomizer from "../components/ThemeCustomizer";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("../components/Topbar"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const Footer = React.lazy(() => import("../components/Footer"));
const RightSidebar = React.lazy(() => import("../components/RightSidebar"));

const loading = () => <div className="text-center"></div>;

interface HorizontalLayoutProps extends PropsWithChildren {
	changeLayout: (layout: string) => LayoutAction;
	layout: any;
}

const HorizontalLayout: FC<HorizontalLayoutProps> = (props) => {
	const { children, layout } = props;
	const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);

	useEffect(() => {
		changeLayout(layoutConstants.LAYOUT_HORIZONTAL);
	}, []);

	const openMenu = (e) => {
		e.preventDefault();
		setIsMenuOpened((prevState) => !prevState);
		if (document.body) document.body.classList.add("sidebar-enable");
	};

	return (
		<div className="app">
			<div className="wrapper">
				<div className="content-page">
					<div className="content">
						<Suspense fallback={loading()}>
							<Topbar {...props} isMenuOpened={isMenuOpened} openLeftMenuCallBack={openMenu} navCssClasses="topnav-navbar" />
						</Suspense>

						<Suspense fallback={loading()}>
							<Navbar {...props} isMenuOpened={isMenuOpened} />
						</Suspense>

						<Container fluid>
							<Suspense fallback={loading()}>{children}</Suspense>
						</Container>
					</div>

					<Suspense fallback={loading()}>
						<Footer />
					</Suspense>

					<Suspense fallback={loading()}>
						<RightSidebar {...props}>
							<ThemeCustomizer />
						</RightSidebar>
					</Suspense>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};
export default connect(mapStateToProps, { changeLayout })(HorizontalLayout);
