import React from "react";

const UserAvatarDefault = ({ firstname = "", lastname = "", size, email = "", avatar }) => {
	const blockSize = size === "sm" ? "28px" : size === "lg" ? "68x" : "42px";

	return (
		<div
			className="rounded-circle d-flex align-items-center justify-content-center inline-block"
			style={{ width: blockSize, height: blockSize, backgroundColor: avatar.color }}>
			{firstname && lastname && `${firstname.at(0).toUpperCase()} ${lastname.at(0).toUpperCase()}`}
			{!firstname && !lastname && (email?.at(0) || "N/A").toUpperCase()}
		</div>
	);
};

export default UserAvatarDefault;
