import React, { Component, FC, PropsWithChildren, ReactNode } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

interface FormModalProps extends PropsWithChildren {
	modalTitle?: ReactNode;
	displayModal?: boolean;
	toggle?: () => void;
	size?: string;
	extendSize?: boolean;
	extendSizeS?: boolean;
	bodyStyle?: any;
}

const FormModal: FC<FormModalProps> = ({ displayModal, bodyStyle, modalTitle, toggle, size, extendSizeS, extendSize, children }) => {
	return (
		<Modal
			isOpen={displayModal}
			toggle={toggle}
			backdrop={"static"}
			size={size}
			style={extendSize ? { maxWidth: "98%" } : extendSizeS ? { maxWidth: "70%" } : null}>
			<ModalHeader toggle={toggle}>{modalTitle}</ModalHeader>
			<ModalBody style={bodyStyle}>{children}</ModalBody>
			{/*<ModalFooter>
                  <Button color="primary" onClick={this.toggle}>
                      Do Something
                  </Button>{' '}
                  <Button color="secondary" onClick={this.toggle}>
                      Cancel
                  </Button>
              </ModalFooter>*/}
		</Modal>
	);
};
export default FormModal;
