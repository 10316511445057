type Size = {
	value: number | null;
	fraction: number | null;
	uom: string | null;
};

export interface InitSize {
	custom_item: boolean;
	width: Size;
	length: Size;
	height: Size;
}

export const INIT_SIZE: InitSize = {
	custom_item: false,
	width: {
		value: null,
		fraction: null,
		uom: null,
	},
	length: {
		value: null,
		fraction: null,
		uom: null,
	},
	height: {
		value: null,
		fraction: null,
		uom: null,
	},
};

type Location = {
	hq: boolean;
	primaryLocation: boolean;
	_id: string;
	name: string;
	address: string;
	city: string;
	state: string;
	zipCode: string;
};

type Contact = {
	locations: string[];
	primary: boolean;
	_id: string;
	name: string;
	number: string;
	email: string;
};

interface Vendor {
	_id: string;
	name: string;
	createdAt: string;
	updatedAt: string;
	__v: number;
	locations: Location[];
	contacts: Contact[];
}

export interface VariantInit {
	_id?: string | null;
	manufacturer_code?: string | null;
	description?: string | null;
	vendor_code?: string | null;
	vendor?: Vendor | null;
	finish?: string | null;
	color?: string | null;
	uom?: string | null;
	sub_description?: string | null;
	size?: any;
	price?: number | null;
	createdAt?: string | null;
	updatedAt?: string | null;
}

export const VARIANT_INIT: VariantInit = {
	manufacturer_code: null,
	description: null,
	vendor_code: null,
	vendor: null,
	finish: null,
	color: null,
	uom: null,
	sub_description: null,
	size: null,
	price: null,
};
