export enum AddressType {
	JOB_SITE = "JOB_SITE",
	WAREHOUSE = "WAREHOUSE",
	CUSTOM = "CUSTOM",
	VENDOR = "VENDOR",
}

export enum LocationsAndContactsTabsType {
	LOCATIONS = "LOCATIONS",
	CONTACTS = "CONTACTS",
}
