import React, { useContext, useEffect, useState } from "react";
import { ConversationContext } from "../context/ConversationContextProvider";
import { Card, Row } from "reactstrap";
import Chat from "../pages/conversations/components/Chat";
import { useQuery } from "@tanstack/react-query";
import { getSelectedConversation } from "../api/conversationsApi";
import Spinner from "./Spinner";
import Icon from "@mdi/react";
import { mdiArrowDown, mdiArrowUp, mdiClose } from "@mdi/js";

const ConversationWidget = () => {
	const { isModalOpen, setIsModalOpen, conversationId } = useContext(ConversationContext);
	const [collapsed, setCollapsed] = useState(false);
	const {
		data: selectedConversation,
		isLoading,
		refetch,
	} = useQuery({ queryKey: ["widgetConversation", conversationId], queryFn: () => getSelectedConversation(conversationId), enabled: !!conversationId });

	useEffect(() => {
		if (conversationId) refetch();
	}, [conversationId]);
	const toggleModal = () => setIsModalOpen(!isModalOpen);

	return (
		<>
			{isModalOpen && (
				<Card className="mb-0 position-fixed bottom-1 right-2" style={{ width: "400px", height: collapsed ? "auto" : "600px", zIndex: '1000' }}>
					<div className="position-absolute top-2 right-2 z-index-3 p-1 cursor-pointer font-weight-bold">
						<span onClick={() => setCollapsed((prevState) => !prevState)}>
							<Icon path={collapsed ? mdiArrowUp : mdiArrowDown} size={1} />
						</span>
						<span onClick={toggleModal}>
							<Icon className={"ml-2"} path={mdiClose} size={1} />
						</span>
					</div>
					<div className="h-100">
						{!isLoading ? (
							<Chat conversation={selectedConversation} collapsed={collapsed} showTargetLink={false} />
						) : (
							<Row className="w-100 d-flex justify-content-center pt-3 px-0">
								<Spinner size={"md"} type="grow" />
							</Row>
						)}
					</div>
				</Card>
			)}
		</>
	);
};

export default ConversationWidget;
