import { browserLocalPersistence, getAuth, sendPasswordResetEmail, setPersistence, signInWithCustomToken, signInWithEmailAndPassword, signOut, User } from "firebase/auth";
import { getApp, initializeApp } from "firebase/app";
import { API_KEY, APP_ID, AUTH_DOMAIN, MESSAGING_SENDER_ID, PROJECT_ID, STORAGE_BUCKET } from "../config";

export const firebaseConfig = {
	apiKey: API_KEY,
	authDomain: AUTH_DOMAIN,
	projectId: PROJECT_ID,
	storageBucket: STORAGE_BUCKET,
	messagingSenderId: MESSAGING_SENDER_ID,
	appId: APP_ID,
};

function createFirebaseApp(config) {
	try {
		return getApp();
	} catch {
		return initializeApp(config);
	}
}

export const firebaseApp = createFirebaseApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

const init = async () => {
	await setPersistence(auth, browserLocalPersistence);
};
init();

export const onAuthStateChanged = (authStateChanged) => {
	return auth.onAuthStateChanged(authStateChanged);
};

export const passwordReset = (email: string) => {
	return sendPasswordResetEmail(auth, email);
};

export const signInWithEmail = (email: string, password: string) => {
	return signInWithEmailAndPassword(auth, email, password);
};

export const signInWithToken = (token: string) => {
	return new Promise<User>((resolve, reject) => {
		signInWithCustomToken(auth, token)
			.then((userCredential) => {
				resolve(userCredential.user);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const logoutUser = async () => {
	await signOut(auth);
};

export const AUTH_ERROR_CODES = {
	CREDENTIAL_TOO_OLD_LOGIN_AGAIN: "auth/requires-recent-login",
	EMAIL_EXISTS: "auth/email-already-in-use",
	INTERNAL_ERROR: "auth/internal-error",
	INVALID_AUTH: "auth/invalid-user-token",
	INVALID_CODE: "auth/invalid-verification-code",
	INVALID_EMAIL: "auth/invalid-email",
	INVALID_PASSWORD: "auth/wrong-password",
	INVALID_RECIPIENT_EMAIL: "auth/invalid-recipient-email",
	NULL_USER: "auth/null-user",
	REJECTED_CREDENTIAL: "auth/rejected-credential",
	TIMEOUT: "auth/timeout",
	TOKEN_EXPIRED: "auth/user-token-expired",
	USER_CANCELLED: "auth/user-cancelled",
	USER_DELETED: "auth/user-not-found",
	USER_DISABLED: "auth/user-disabled",
	USER_MISMATCH: "auth/user-mismatch",
	USER_SIGNED_OUT: "auth/user-signed-out",
	TOO_MANY_REQUESTS: "auth/too-many-requests",
	FUNCTIONS_NOT_FOUND: "functions/not-found",
};