// @flow
import React, { useEffect } from "react";
import Routes from "./routes/Routes";
import "./firebase/firebaseMessagingSW";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Themes
import "./assets/scss/Saas.scss";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import { Helmet } from "react-helmet";
import { PackingListContextProvider } from "./helpers/PackingListContext";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AppContextProviders from "./context/AppContextProviders";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IMS_ENV } from "./config";
import StateAndLocationProvider from "./context/StateAndLocationContext";

const queryClient = new QueryClient();
const THEME = createTheme({
	typography: {
		fontFamily: `"Nunito", , sans-serif`,
		fontSize: 13,
	},
});

/**
 * Main app component
 */
const App = () => {
	const isDev = IMS_ENV === "development";

	return (
		<QueryClientProvider client={queryClient}>
			<StateAndLocationProvider>
				<PackingListContextProvider>
					<AppContextProviders>
						<ThemeProvider theme={THEME}>
							<>
								<Helmet>
									<link rel="shortcut icon" href={`/favicon${isDev ? "-dev" : ""}.ico`} />
								</Helmet>
								<Routes />
							</>
						</ThemeProvider>
					</AppContextProviders>
				</PackingListContextProvider>
			</StateAndLocationProvider>
			{isDev && <ReactQueryDevtools initialIsOpen={false} />}
		</QueryClientProvider>
	);
};

export default App;
