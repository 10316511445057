import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { axiosInstance } from "../../helpers";
import Bugsnag from "@bugsnag/browser";
import Spinner from "../../components/Spinner";

const baseRoute = "/notifications/test";

const Test = () => {
	const [daily, setDaily] = useState(false);
	const [weekly, setWeekly] = useState(false);
	const [inOneWeek, setInOneWeek] = useState(false);
	const [arrivingThisWeek, setArrivingThisWeek] = useState(false);
	const sendDaily = async (testing = false) => {
		try {
			setDaily(true);
			await axiosInstance.get(`${baseRoute}/daily?testing=${testing}`);
			toast.success("Daily Due test success", {
				toastId: "sendDailySuccess",
				position: "top-right",
				autoClose: 5000,
			});
		} catch (e) {
			Bugsnag.notify(e);
			toast.error("Daily Due test fail", {
				toastId: "sendDailyFail",
				position: "top-right",
				autoClose: 5000,
			});
		}
		setDaily(false);
	};

	const sendWeekly = async (testing = false) => {
		try {
			setWeekly(true);
			await axiosInstance.get(`${baseRoute}/weekly?testing=${testing}`);
			toast.success("Weekly Due test success", {
				toastId: "sendWeeklySuccess",
				position: "top-right",
				autoClose: 5000,
			});
		} catch (e) {
			Bugsnag.notify(e);
			toast.error("Weekly Due test fail", {
				toastId: "sendWeeklyFail",
				position: "top-right",
				autoClose: 5000,
			});
		}
		setWeekly(false);
	};

	const sendInOneWeekly = async (testing = false) => {
		try {
			setInOneWeek(true);
			await axiosInstance.get(`${baseRoute}/in-one-week?testing=${testing}`);
			toast.success("In one week Due test success", {
				toastId: "sendInOneWeeklySuccess",
				position: "top-right",
				autoClose: 5000,
			});
		} catch (e) {
			Bugsnag.notify(e);
			toast.error("In one week Due test fail", {
				toastId: "sendInOneWeeklyFail",
				position: "top-right",
				autoClose: 5000,
			});
		}
		setInOneWeek(false);
	};

	const sendArrivingThisWeek = async (testing = false) => {
		try {
			setArrivingThisWeek(true);
			await axiosInstance.get(`${baseRoute}/arriving-this-week?testing=${testing}`);
			toast.success("Arriving this week test success", {
				toastId: "arrivingThisWeekSuccess",
				position: "top-right",
				autoClose: 5000,
			});
		} catch (e) {
			Bugsnag.notify(e);
			toast.error("Arriving this week test fail", {
				toastId: "arrivingThisWeekFail",
				position: "top-right",
				autoClose: 5000,
			});
		}
		setArrivingThisWeek(false);
	};

	return (
		<>
			<h1>Test scheduled notifications</h1>
			<div style={{ display: "grid", gridTemplateColumns: "max-content max-content", gap: "15px" }}>
				<Button onClick={sendDaily}>
					Send Daily Payment Due
					{!daily ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={() => sendDaily(true)}>
					Send Daily Payment Due (Random)
					{!daily ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>

				<Button onClick={sendWeekly}>
					Send Weekly Payment Due
					{!weekly ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={() => sendWeekly(true)}>
					Send Weekly Payment Due (Random)
					{!weekly ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={sendInOneWeekly}>
					Send In one Week Payment Due
					{!inOneWeek ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={() => sendInOneWeekly(true)}>
					Send In one Week Payment Due (Random)
					{!inOneWeek ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={sendArrivingThisWeek}>
					Send Arriving this Week
					{!arrivingThisWeek ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
				<Button onClick={() => sendArrivingThisWeek(true)}>
					Send Arriving this Week (Random)
					{!arrivingThisWeek ? <i className={"mdi mdi-send"} /> : <Spinner size={"xs"} type="grow" />}
				</Button>
			</div>
		</>
	);
};

export default Test;
