import chroma from "chroma-js";

export const MULTISELECT_DEFAULT = "#727cf5";
export const MULTISELECT_PRIMARY = "red";

export const COLOR_STYLES = {
	multiValue: (styles, { data }) => {
		const color = chroma(data.color);
		return {
			...styles,
			backgroundColor: `${color.alpha(0.1).css()} !important`,
		};
	},
	multiValueLabel: (styles, { data }) => {
		if (data.isFixed) {
			return {
				...styles,
				color: `${data.color} !important`,
				paddingRight: "6px",
			};
		} else {
			return {
				...styles,
				color: `${data.color} !important`,
			};
		}
	},
	multiValueRemove: (styles, { data }) => {
		if (data.isFixed) {
			return {
				...styles,
				color: data.color,
				display: "none",
				":hover": {
					backgroundColor: data.color,
					color: "white",
				},
			};
		} else {
			return {
				...styles,
				color: data.color,
				":hover": {
					backgroundColor: data.color,
					color: "white",
				},
			};
		}
	},
};
