import React, { useState } from "react";
import { Col, FormFeedback, FormGroup } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import ItemBankLabel from "./ItemBankLabel";
import { sortSelectOptionsByValue } from "../../../helpers/itemBankUtils";
import DeleteForm from "../../../components/custom/DeleteForm";
import FormModal from "../../../components/custom/FormModal";
import Bugsnag from "@bugsnag/js";

interface ItemBankCreatableSelectProps {
	id: string;
	defaultOption?: boolean;
	setFieldValue: (name: string, value: any) => void;
	onChange: (e: any) => void;
	label: string;
	color?: string;
	isMulti?: boolean;
	options?: any;
	isRequired?: boolean;
	width?: any;
	defaultValue: any;
	placeholder?: string;
	isDisabled?: boolean;
	isNotClearable?: boolean;
	error?: any;
	value?: any;
	variants?: any;
	sort?: any;
}

const ItemBankCreatableSelect = ({
	id,
	defaultOption,
	setFieldValue,
	onChange,
	label,
	color,
	isMulti,
	options,
	isRequired,
	width,
	defaultValue,
	placeholder,
	isDisabled,
	isNotClearable,
	error,
	value,
	variants,
	sort,
}: ItemBankCreatableSelectProps) => {
	const [deleteParams, setDeleteParams] = useState([]);

	return (
		<Col xs={width}>
			<FormGroup>
				{label && <ItemBankLabel label={label} isRequired={isRequired} color={color} />}
				<CreatableSelect
					className="react-select"
					classNamePrefix="react-select"
					name={id}
					id={id}
					isDisabled={isDisabled}
					isMulti={isMulti}
					isClearable={isNotClearable ? false : true}
					isSearchable
					openMenuOnFocus
					onChange={(e) => {
						if (setFieldValue) {
							if (Array.isArray(e)) {
								const newValue = e?.map(({ value }) => value);

								if (variants) {
									let difference = value.filter((x) => {
										let contains = false;

										for (let index = 0; index < e.length; index++) {
											const element = e[index];
											try {
												if (element?.label?.includes(x)) {
													contains = true;
												}
											} catch (e) {
												Bugsnag.notify(e);
											}
										}

										return !contains;
									});

									let newCheck = e.filter((x) => {
										try {
											if (!value.includes(x?.label)) {
												return true;
											}
										} catch (e) {
											Bugsnag.notify(e);
										}
									});

									let newResult = false;

									if (newCheck[0]) {
										if (newCheck[0].__isNew__) newResult = true;
									}

									var variantsContain = variants.filter((variant) => variant.manufacturer_code === difference[0]);

									if (variantsContain.length > 0 && !newResult) {
										setDeleteParams([id, newValue, setFieldValue]);
									} else {
										setFieldValue(id, newValue);
									}
								} else {
									setFieldValue(id, newValue);
								}
							} else if (isMulti) {
								if (variants) {
									let difference = value.filter((x) => ![].includes(x));

									var variantsContain = variants.filter((variant) => variant.manufacturer_code === difference[0]);
									if (variantsContain.length > 0) {
										setDeleteParams([id, [], setFieldValue]);
									} else {
										setFieldValue(id, []);
									}
								} else {
									setFieldValue(id, []);
								}
							} else {
								setFieldValue(id, e?.value);
							}
						} else if (onChange) {
							onChange(e);
						}
					}}
					placeholder={placeholder}
					options={sort ? options.sort(sortSelectOptionsByValue) : options}
					required={isRequired}
					value={defaultOption ? defaultOption : getCreatableSelectValue(defaultValue, label)}
				/>
				{error && <FormFeedback valid={false}>{error}</FormFeedback>}
			</FormGroup>
			<FormModal modalTitle={"Are You Sure?"} toggle={() => setDeleteParams([])} displayModal={deleteParams.length > 0 ? true : false}>
				<DeleteForm
					warningText="This manufacturer code is linked to a variant(s). Enter ‘DELETE’ to confirm."
					// loading = {this.props.loading}
					buttonColor="danger"
					handleSubmit={() => {
						deleteParams[2](deleteParams[0], deleteParams[1]);
						setDeleteParams([]);
					}}
				/>
			</FormModal>
		</Col>
	);
};

export const getCreatableSelectValue = (value: any, label?: string) => {
	if (value == '"' && !label) {
		value = "IN";
	}

	if (value == "'" && !label) {
		value = "FT";
	}

	if (Array.isArray(value)) {
		return value.map((valueField) => ({ label: valueField, value: valueField }));
	}
	return value
		? {
				value: value,
				label: label || value,
		  }
		: null;
};

export default ItemBankCreatableSelect;
