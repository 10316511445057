import fileIconPdf from "../assets/images/file_type_pdf.svg";
import fileIconExcel from "../assets/images/microsoft_office_excel.svg";
import fileIconPowerPoint from "../assets/images/microsoft_power_point_office.svg";
import fileIconWord from "../assets/images/microsoft_office_word.svg";

export const fileTypes = [
	{ placeholderImage: null, mimeType: "image/*" },
	{ placeholderImage: null, mimeType: ".heif" },
	{ placeholderImage: null, mimeType: ".heic" },
	{ placeholderImage: fileIconPdf, mimeType: "application/pdf" },
	{ placeholderImage: fileIconExcel, mimeType: "text/csv" },
	{ placeholderImage: fileIconExcel, mimeType: "application/vnd.ms-excel" },
	{ placeholderImage: fileIconExcel, mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
	{
		placeholderImage: fileIconPowerPoint,
		mimeType: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
	},
	{ placeholderImage: fileIconPowerPoint, mimeType: "application/vnd.ms-powerpoint" },
	{ placeholderImage: fileIconWord, mimeType: "application/msword" },
	{
		placeholderImage: fileIconWord,
		mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	},
];
